import { useState, useEffect, useCallback } from 'react'
import { API } from '@enkla-gui'

export const useGetAll = ({ endpoint, params = null, filter: _filter = {} }) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [sorting, setSorting] = useState({})
  const [filter, setFilter] = useState(_filter)

  const get = useCallback(async () => {
    let allData = []
    let currentPage = 1
    let lastPage = 1

    try {
      setLoading(true)
      setError(null)

      do {
        const response = await API.get(endpoint, { ...params, ...filter, ...sorting, page: currentPage })
        const { data: responseData, last_page } = response

        allData = [...allData, ...(responseData || [])]
        lastPage = last_page
        currentPage += 1
      } while (currentPage <= lastPage)

      setData(allData)
      setLoading(false)
    } catch (err) {
      setError(err)
      setLoading(false)
    }
  }, [endpoint, params, sorting, filter])

  useEffect(() => {
    get()
  }, [get])

  return { data, loading, error, fetch: get, setSorting, setFilter, sorting }
}
