export const tickethelper = {
  status(status) {
    switch (status) {
      case 'NEW':
        return {
          label: 'Ny',
          title: 'nya',
          color: 'success'
        }
      case 'OPEN':
        return {
          label: 'Pågående',
          title: 'pågående',
          color: 'warning'
        }
      case 'RESOLVED':
        return {
          title: 'lösta',
          label: 'Löst',
          color: 'light'
        }
      default:
        break
    }
  },
  type(type) {
    switch (type) {
      case 'REPORT':
        return 'Felanmälan'
      case 'QUESTION':
        return 'Fråga'
      case 'INTERNAL':
        return 'Internt'
      default:
        break
    }
  }
}
