import React from 'react'

export default function Button ({label, value, link, icon = 'fas fa-chevron-right', hidden = false, cols = 'col', target='_self'}) {
  return (
    <div class={`component_FormButton ${cols}`} hidden={hidden}>
      <div class='form-group'>
        <label>{label}</label>
        <div class='input-group'>
          <input disabled value={value} type="text" class="form-control" />
          <a href={link} target={target} class="input-group-text"><i class={icon} /></a>
        </div>
      </div>
    </div>
  )
}
