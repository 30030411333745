import React, { useState, useEffect, useRef } from 'react'
import { Card, date } from '@enkla-gui'
import DatePicker from 'react-datepicker'
export default function Filter(props) {
  var [filter, setFilter] = useState({})
  const timer = useRef(null)

  useEffect(() => {
    props.useFilter(filter)
  }, [filter])

  const clear = key => {
    var copy = filter
    delete copy[key]
    setFilter({ ...copy })
  }
  if (props.hasOwnProperty('show') && !props.show) return ''
  return (
    <Card title='Filtrera' icon='fas fa-filter'>
      <div class='row'>
        {props.filters.map(_filter => {
          switch (_filter.type) {
            case 'select':
              return (
                <div class={`form-group col${props.filters.length > 3 ? '-4' : ''}`}>
                  <label>{_filter.label}</label>
                  {_filter.key in filter && (
                    <span onClick={() => clear(_filter.key)} class='select-clear'>
                      <i class='fas fa-times' />
                    </span>
                  )}
                  <select type='select' class='form-select' onChange={e => setFilter({ ...filter, [_filter.key]: e.target.value })}>
                    <option disabled selected={!(_filter.key in filter)} value=''>
                      Välj...
                    </option>
                    {_filter.values.map(value => (
                      <option value={value.value}>{value.label}</option>
                    ))}
                  </select>
                </div>
              )
            case 'input':
              return (
                <div class={`form-group col${props.filters.length > 3 ? '-4' : ''}`}>
                  <label>{_filter.label}</label>
                  <input
                    onChange={e => {
                      clearTimeout(timer.current)
                      timer.current = setTimeout(() => {
                        if (e.target.value === '') return clear(_filter.key)
                        setFilter({ ...filter, [_filter.key]: e.target.value })
                      }, 500)
                    }}
                    type='text'
                    class='form-control'
                  />
                </div>
              )
            case 'daterange':
              return (
                <div class={`form-group col${props.filters.length > 3 ? '-4' : ''}`}>
                  <label>{_filter.label}</label>
                  <DatePicker
                    selectsRange={true}
                    startDate={
                      filter.hasOwnProperty(_filter.key) && JSON.parse(filter[_filter.key]).start !== null
                        ? new Date(JSON.parse(filter[_filter.key]).start)
                        : ''
                    }
                    endDate={
                      filter.hasOwnProperty(_filter.key) && JSON.parse(filter[_filter.key]).stop !== null ? new Date(JSON.parse(filter[_filter.key]).stop) : ''
                    }
                    className='form-control'
                    locale='sv'
                    dateFormat='yyyy-MM-dd'
                    onChange={update => {
                      if (update[0] === null && update[1] === null) return clear(_filter.key)
                      setFilter({
                        ...filter,
                        [_filter.key]: JSON.stringify({
                          start: date.fixTimezone(update[0]),
                          stop: date.fixTimezone(update[1])
                        })
                      })
                    }}
                    isClearable={true}
                  />
                </div>
              )
            default:
              return null
          }
        })}
      </div>
    </Card>
  )
}
