import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { invoicebasehelper, date, notify } from '@/_helpers'
import { useGet, ViewHeader, InfoBox, Table, Pagination, Card, Filter, usePost } from '@enkla-gui'
import { filters, icons } from '@/_config'

export default function Invoicebases() {
  const { status } = useParams()

  var [showFilter, setShown] = useState(true)

  const {
    data: invoicebases,
    setSorting,
    setPage,
    filter,
    setFilter,
    sorting,
    fetch: fetchInvoicebases,
    loading: loadingInvoicebases
  } = useGet({ endpoint: `api/invoicebase`, filter: status ? { status: status } : {} })

  const { data: billable, fetch: fetchBillable } = useGet({ endpoint: `api/auth/billable` })

  const { post: mailall, loading } = usePost({
    confirm: `Vill du skicka de ${billable?.stat_not_sent_invoices} hyresavier som väntar?`,
    success: () => {
      fetchBillable()
      fetchInvoicebases()
    }
  })
  const { post: mail, loading: mailing } = usePost({
    confirm: 'Vill du skicka denna hyresavi?',
    success: () => {
      fetchBillable()
      fetchInvoicebases()
    }
  })
  useEffect(() => {
    if (status && filter?.status !== status) setFilter({ status: status })
  }, [status])

  return (
    <>
      <ViewHeader
        icon={icons.invoice}
        title='Hyresavier'
        subtitle={invoicebasehelper.status(status)?.label ?? 'Alla'}
        buttons={[
          { icon: 'fa-filter', title: `${showFilter ? 'Dölj' : 'Visa'} filter`, callback: () => setShown(!showFilter), hidden: status },
          {
            hidden: status !== 'ready',
            icon: icons.send,
            loading: loading,
            title: 'Skicka alla hyresavier',
            callback: () => mailall({ endpoint: 'api/invoicebase/mailall' })
          }
        ]}
      />
      <div class='row'>
        <InfoBox loading={!invoicebases} title='Inväntar betalning' subtitle={billable?.stat_unpaid_invoices} icon={icons.money} />
        <InfoBox loading={!invoicebases} title='Ej skickade' subtitle={billable?.stat_not_sent_invoices} icon={icons.email} />
        <InfoBox loading={!invoicebases} title='Förfallna' subtitle={billable?.stat_overdue_invoices} icon={icons.warning} />
      </div>
      {!status && <Filter show={showFilter} filters={filters.invoice} useFilter={_filter => setFilter({ ..._filter })} />}
      <Card>
        <Table
          loadercount={15}
          fetching={loadingInvoicebases}
          empty={{
            text: `Det finns inte några hyresavier`,
            icon: icons.invoice
          }}
          rows={invoicebases?.data}
          link={`/#!/invoicebase/@{id}`}
          sorting={sorting}
          useSorting={sort => setSorting({ ...sort })}
          functions={{
            status: status => <span class={`badge w-100 bg-${invoicebasehelper.status(status).color}`}>{invoicebasehelper.status(status).label}</span>,
            lease: invoice =>
              invoice.lease ? (
                <a onClick={e => e.stopPropagation()} href={`/#!/lease/${invoice.lease.id}`}>
                  {invoice.lease.entity.name}
                </a>
              ) : (
                'Saknar koppling'
              ),
            period: invoice => date.short(invoice.start) + ' - ' + date.short(invoice.stop),
            reference: ref => (ref === null ? 'Ej skapad' : ref),
            mail: ib => (
              <button
                disabled={mailing}
                class='btn btn-light btn-sm'
                onClick={e => {
                  e.stopPropagation()
                  mail({ endpoint: `api/invoicebase/${ib.id}/mail` })
                }}>
                <i class={mailing ? icons.loading : icons.send} /> {mailing ? 'Skickar' : 'Skicka nu'}
              </button>
            )
          }}
          keys={[
            '_this>function:mail',
            'status>function:status',
            'send_date',
            'title',
            'payment_left>currency',
            '_this>function:period',
            'reference>function:reference',
            '_this>function:lease'
          ]}
          headers={[
            {
              class: 'min',
              hidden: status !== 'ready'
            },
            {
              key: 'status',
              value: 'Status',
              sortable: true,
              class: 'min'
            },
            {
              key: 'send_date',
              value: 'Skickas automatiskt',
              hidden: ['sent', 'error', 'overdue'].includes(status),
              class: 'min'
            },
            {
              key: 'invoice_type',
              value: 'Typ',
              sortable: true,
              class: 'min'
            },
            {
              key: 'payment_left',
              sortable: true,
              value: 'Kvar att betala',
              hidden: !['overdue', 'sent', undefined].includes(status)
            },
            {
              key: 'stop',
              sortable: true,
              value: 'Period'
            },
            {
              key: 'reference',
              value: 'Referens',
              sortable: true,
              class: 'min'
            },
            {
              key: '',
              value: 'Uthyrning'
            }
          ]}
        />
        <Pagination data={invoicebases} fetch={setPage} pushToHistory />
      </Card>
    </>
  )
}
