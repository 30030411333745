import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import Modal from 'react-modal'
import { notify, date } from '@/_helpers'
import moment from 'moment'
import { useParams } from 'react-router-dom'
import { API, Card, Pagination, NoContentBox, Submit, Input, Select, Date, TextArea } from '@enkla-gui'
import './styles.scss'

export default function Events(props) {
  const { register, handleSubmit, reset, control } = useForm({
    defaultValues: {
      title: '',
      description: '',
      type: 'INFO',
      date: moment().unix()
    }
  })
  const { register: updateRegister, handleSubmit: updateSubmit, reset: updateReset, control: updateControl } = useForm()
  let { page } = useParams()

  const [error, setError] = useState(null)
  const [sending, setSending] = useState(false)
  const [events, setEvents] = useState(false)
  const [modal, setModal] = useState(false)
  const [updateModal, setUpdateModal] = useState(false)

  useEffect(() => {
    if (props.id === undefined) return
    fetchEvents()
  }, [props.id, props.refresh])

  const fetchEvents = async (p = page || 1) => {
    if (props.id === null) return
    API.get(`api/event/${props.type}/${props.id}`, { page: p }).then(events => setEvents(events))
  }

  const create = data => {
    setSending(true)
    API.post('api/event', data)
      .then(res => {
        notify.toast('Händelse skapad')
        reset()
        fetchEvents()
        setModal(false)
        setError(false)
        setSending(false)
      })
      .catch(([err, res]) => {
        setError({
          message: res.message,
          data: res.data
        })
        setSending(false)
      })
  }

  const remove = async target => {
    if (!(await notify.confirm())) return
    API.delete(`api/event/${target.id}`)
      .then(res => {
        notify.toast('Händelse raderad')
        fetchEvents()
      })
      .catch(([err, res]) => {
        notify.toast(`Borttagningen misslyckades: ${res.message}`, 'error')
      })
  }

  const update = data => {
    setSending(true)
    API.patch('api/event', data)
      .then(res => {
        notify.toast('Händelse uppdaterad')
        fetchEvents()
        setUpdateModal(false)
        setError(false)
        setSending(false)
      })
      .catch(([err, res]) => {
        setError({
          message: res.message,
          data: res.data
        })
        setSending(false)
      })
  }

  const getTaskColor = type => {
    switch (type) {
      case 'INFO':
        return 'primary'
      case 'WARNING':
        return 'warning'
      case 'IMPORTANT':
        return 'danger'
      case 'OTHER':
        return 'dark'
      default:
    }
  }
  return (
    <>
      <Card
        loading={!events}
        title='Händelser'
        icon='fas fa-fw fa-calendar-day'
        buttons={{
          header: [{ callback: () => setModal(true), icon: 'fas fa-plus-square text-light', text: 'Skapa' }]
        }}>
        {events?.data && events.data.length !== 0 && (
          <div class='task-wrapper'>
            <div class='task-list'>
              {events.data.map((event, i) => {
                return (
                  <li class={`d-sm-flex ${i === 0 && 'first'} ${i === events?.data.length - 1 && 'last'} `}>
                    <div style={{ overflow: 'auto' }}>
                      <i class={`task-icon bg-${getTaskColor(event.type)}`} />
                      <h6 class='fw-semibold text'>
                        {event.title}
                        <span class='text-muted fs-11 fw-normal mx-2'>{props.type === 'entity' ? date.short(event.date) : date.full(event.date)}</span>{' '}
                      </h6>
                      <p class='text-muted fs-12'>{event.description}</p>
                    </div>
                    <div class='d-md-flex event-buttons ms-auto'>
                      <button
                        onClick={() => {
                          updateReset(event)
                          setUpdateModal(true)
                        }}
                        class='btn btn-sm btn-light mr-5'>
                        <i class='fas fa-pencil-alt' />
                      </button>
                      <button onClick={() => remove(event)} class='btn btn-sm btn-light'>
                        <i class='fas fa-trash' />
                      </button>
                    </div>
                  </li>
                )
              })}
            </div>
            <Pagination data={events} fetch={fetchEvents} />
          </div>
        )}
        {events?.data && events.data.length === 0 && <NoContentBox text='Det finns inte några händelser' icon='fas fa-calendar-day' />}
      </Card>
      <Modal isOpen={modal} onRequestClose={() => setModal(false)} className='no-padding'>
        <Card
          title='Skapa Händelse'
          icon='fas fa-fw fa-calendar-plus'
          buttons={{
            header: [{ callback: () => setModal(false), icon: 'fas fa-times', text: 'Avbryt', bg: 'light' }],
            footer: [<Submit onSubmit={handleSubmit(create)} text='Skapa' sending={sending} />]
          }}>
          <input type='hidden' {...register('eventable_id')} value={props.id} />
          <input type='hidden' {...register('eventable_type')} value={props.type} />
          <div class='row mb-3'>
            <Input controls={{ register }} name='title' options={{ required: true }} label='Titel' error={error} />
          </div>
          <div class='row mb-3'>
            <Select
              controls={{ register }}
              name='type'
              options={{ required: true }}
              label='Typ'
              error={error}
              values={[
                { key: 'Info', value: 'INFO' },
                { key: 'Varning', value: 'WARNING' },
                { key: 'Viktig', value: 'IMPORTANT' },
                { key: 'Annat', value: 'OTHER' }
              ]}
            />
          </div>
          <div class='row'>
            <Date label='Datum' controls={{ control }} name='date' error={error} />
          </div>
          <div class='row mb-3'>
            <TextArea controls={{ register }} name='description' options={{ required: false }} label='Beskrivning' error={error} />
          </div>
        </Card>
      </Modal>
      <Modal isOpen={updateModal} onRequestClose={() => setUpdateModal(false)} className='no-padding'>
        <Card
          title='Redigera Händelse'
          icon='fas fa-fw fa-calendar-plus'
          buttons={{
            header: [{ callback: () => setUpdateModal(false), icon: 'fas fa-times', text: 'Avbryt', bg: 'light' }],
            footer: [<Submit onSubmit={updateSubmit(update)} text='Uppdatera' sending={sending} />]
          }}>
          <input type='hidden' {...updateRegister('entity_id')} value={props.id} />
          <div class='row mb-3'>
            <Input controls={{ register: updateRegister }} name='title' options={{ required: true }} label='Titel' error={error} />
          </div>
          <div class='row mb-3'>
            <Select
              controls={{ register: updateRegister }}
              name='type'
              options={{ required: true }}
              label='Typ'
              error={error}
              values={[
                { key: 'Info', value: 'INFO' },
                { key: 'Varning', value: 'WARNING' },
                { key: 'Viktig', value: 'IMPORTANT' },
                { key: 'Annat', value: 'OTHER' }
              ]}
            />
          </div>
          <div class='row'>
            <Date today label='Datum' controls={{ control: updateControl }} name='date' error={error} />
          </div>
          <div class='row mb-3'>
            <TextArea controls={{ register: updateRegister }} name='description' options={{ required: false }} label='Beskrivning' error={error} />
          </div>
        </Card>
      </Modal>
    </>
  )
}
