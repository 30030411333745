import React, { useState, useEffect } from 'react'
import './styles.scss'
import { v4 as uuidv4 } from 'uuid'
import { NoContentBox, API, notify } from '@enkla-gui'
import { icons } from '@config'
export default function MaintenanceTasksHandler({ activity, callback }) {
  const [loading, setLoading] = useState(false)
  let patch = data => {
    setLoading(true)
    API.patch(`api/maintenance`, data).then(async res => {
      notify.toast('Uppgiften har uppdaterats')
      await callback()
      setLoading(false)
    })
  }

  const updateTask = (task, status) => {
    if (activity?.status !== 'IN_PROGRESS') return notify.toast('Det går bara att utföra uppgifter när aktiviteten är pågående', 'info')
    task.status = status
    patch(activity)
  }

  return (
    <div className='component_MaintenanceTasks'>
      <div className='tasks-wrapper'>
        {activity?.tasks.map((task, index) => (
          <div key={index} className={`taskerino-wrapper taskerino-${task.status}`}>
            {(() => {
              switch (task.status) {
                case 'COMPLETE':
                  return (
                    <button disabled={loading} class='taskeritask btn-success' onClick={() => updateTask(task, 'FAILED')}>
                      <i class={icons.check} />
                    </button>
                  )
                case 'FAILED':
                  return (
                    <button disabled={loading} class='taskeritask btn-danger' onClick={() => updateTask(task, 'INCOMPLETE')}>
                      <i class={icons.cancel} />
                    </button>
                  )
                case 'INCOMPLETE':
                  return (
                    <button disabled={loading} class='taskeritask btn-gray' onClick={() => updateTask(task, 'COMPLETE')}>
                      <i class={icons.check} />
                    </button>
                  )
              }
            })()}
            <br />
            <div className='task-task'>{task.task}</div>
          </div>
        ))}
      </div>
    </div>
  )
}
