import authHeader from './auth-header.js'
import handleResponse from './handle-response.js'
import { core } from '@config'

const _requestOptions = {
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
}
export const API = {
  post: function (endpoint, body, requestOptions = {}) {
    var options = { method: 'POST', ..._requestOptions, ...requestOptions, body: JSON.stringify(body) }
    if (!options.headers.hasOwnProperty('Authorization')) options.headers = { ...options.headers, ...authHeader() }
    return window.fetch(core.server + endpoint, options).then(handleResponse)
  },
  patch: function (endpoint, body, requestOptions = {}) {
    var options = { method: 'PATCH', ..._requestOptions, ...requestOptions, body: JSON.stringify(body) }
    if (!options.headers.hasOwnProperty('Authorization')) options.headers = { ...options.headers, ...authHeader() }
    return window.fetch(core.server + endpoint, options).then(handleResponse)
  },
  upload: function (endpoint, body) {
    var options = { method: 'POST', headers: {}, body: body }
    if (!options.headers.hasOwnProperty('Authorization')) options.headers = { ...options.headers, ...authHeader() }
    return window.fetch(core.server + endpoint, options).then(handleResponse)
  },
  delete: function (endpoint, body, requestOptions = {}) {
    var options = { method: 'DELETE', ..._requestOptions, ...requestOptions, body: JSON.stringify(body) }
    if (!options.headers.hasOwnProperty('Authorization')) options.headers = { ...options.headers, ...authHeader() }
    return window.fetch(core.server + endpoint, options).then(handleResponse)
  },
  get: function (endpoint, params, requestOptions) {
    var url = new URL(core.server + endpoint)
    var options = { method: 'GET', ..._requestOptions, ...requestOptions }
    if (!options.headers.hasOwnProperty('Authorization')) options.headers = { ...options.headers, ...authHeader() }
    url.search = new URLSearchParams(params).toString()
    return window.fetch(url, options).then(handleResponse)
  },
  blob: function (endpoint, params, requestOptions) {
    var url = new URL(core.server + endpoint)
    var options = { method: 'GET', ..._requestOptions, ...requestOptions }
    if (!options.headers.hasOwnProperty('Authorization')) options.headers = { ...options.headers, ...authHeader() }
    url.search = new URLSearchParams(params).toString()
    return window.fetch(url, options).then(res => res.blob())
  }
}
