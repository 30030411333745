import React, { useState } from 'react'
import Modal from 'react-modal'
import { notify } from '@/_helpers'
import { API, Card, Table, Submit, Select, Input, Date, Help, useSubmit } from '@enkla-gui'
import AddonConnectModal from './connect.js'
import Tippy from '@tippyjs/react'

export default function Addons({ addons, callback, id }) {
  const [connectModal, setConnectModal] = useState(false)
  const { register, submit, control, sending, error, modal, setModal } = useSubmit({
    endpoint: 'api/leaseaddon',
    success: () => callback()
  })
  const {
    register: updateRegister,
    submit: updateSubmit,
    reset: updateReset,
    control: updateControl,
    modal: updateModal,
    setModal: setUpdateModal,
    error: updateError,
    sending: updateSending
  } = useSubmit({
    endpoint: 'api/leaseaddon',
    method: 'patch',
    success: () => callback()
  })

  const remove = async target => {
    if (!(await notify.confirm())) return
    API.delete(`api/leaseaddon/${target.id}`)
      .then(res => {
        notify.toast('Tillägget har tagits bort')
        callback()
      })
      .catch(([err, res]) => {
        notify.toast(`Borttagningen misslyckades: ${res.message}`, 'error')
      })
  }
  return (
    <>
      <Card
        title='Tillägg & Avdrag'
        icon='fas fa-fw fa-receipt'
        buttons={{
          header: [
            <button class='btn btn-primary btn-sm text-light mr-5' onClick={() => setModal(true)}>
              <i class='fas fa-plus-square text-light' /> Skapa
            </button>,
            <button class='btn btn-primary btn-sm text-light' onClick={() => setConnectModal(true)}>
              <i class='fas fa-link text-light' /> Koppla
            </button>
          ]
        }}>
        <Table
          rows={addons}
          empty={{ text: 'Det finns inte några tillägg eller avdrag', icon: 'fas fa-receipt' }}
          functions={{
            setAddon: addon =>
              addon.addon_id === null ? (
                <button
                  class='btn btn-light btn-sm'
                  onClick={() => {
                    updateReset(addon)
                    setUpdateModal(true)
                  }}>
                  <i class='fas fa-edit' />
                </button>
              ) : (
                <a class='btn btn-sm btn-light'>
                  <Tippy content={`Detta är ett globalt ${addon.type.toLowerCase()} och kan därför inte redigeras på en uthyrning`}>
                    <i class='fas fa-fw fa-globe' />
                  </Tippy>
                </a>
              ),
            type: addon => (addon.fee >= 0 ? <span class='badge bg-success w-100'>Tillägg</span> : <span class='badge bg-light w-100'>Avdrag</span>),
            remove: addon => remove(addon)
          }}
          keys={[
            '_this>function:setAddon:fa-edit',
            '_this>function:type',
            'name',
            'fee>currency',
            'tax_rate>%',
            'start>date:short',
            '_this>button:remove:fa-trash-alt'
          ]}>
          <th class='min' />
          <th class='min'>Typ</th>
          <th>Namn</th>
          <th>Belopp</th>
          <th>Momssats</th>
          <th class='min'>Startdatum</th>
          <th class='min' />
        </Table>
      </Card>
      <AddonConnectModal modal={connectModal} setModal={setConnectModal} id={id} fetch={() => callback()} />
      <Modal isOpen={modal} onRequestClose={() => setModal(false)} className='no-padding'>
        <Card
          title='Skapa Tillägg & Avdrag'
          icon='fas fa-fw fa-receipt'
          buttons={{
            header: [{ callback: () => setModal(false), icon: 'fas fa-times', text: 'Avbryt', bg: 'light' }],
            footer: [<Submit onSubmit={submit} text='Skapa' sending={sending} />]
          }}>
          <input type='hidden' {...register('lease_id')} value={id} />
          <Help title='Information' type='info'>
            <p>
              Här kan du skapa upp rader för tillägg och avdrag som gjorts. Detta kommer att användas vid uträkning av månadskostnad. Intervallet är detsamma
              som är angivet på uthyrningen
            </p>
            <p>
              Ett <b>tillägg</b> skapas genom att fylla i ett positivt värde
            </p>
            <p>
              Ett <b>avdrag</b> skapas genom att fylla i ett negativt värde
            </p>
          </Help>
          <div class='row mb-3'>
            <Input controls={{ register }} name='name' options={{ required: true }} label='Namn' error={error} />
          </div>
          <div class='row mb-3'>
            <Input controls={{ register }} name='fee' options={{ required: true }} type='number' label='Belopp' error={error} />
          </div>
          <div class='row mb-3'>
            <Select
              controls={{ register }}
              name='tax_rate'
              options={{ required: true }}
              label='Momssats(%)'
              error={error}
              values={[
                { key: '0%', value: 0 },
                { key: '6%', value: 6 },
                { key: '12%', value: 12 },
                { key: '25%', value: 25 }
              ]}
            />
          </div>
          <div class='row mb-3'>
            <Date label='Startdatum för debitering' controls={{ control }} name='start' error={error} />
          </div>
        </Card>
      </Modal>
      <Modal isOpen={updateModal} onRequestClose={() => setUpdateModal(false)} className='no-padding'>
        <Card
          title='Redigera Tillägg & Avdrag'
          icon='fas fa-fw fa-receipt'
          buttons={{
            header: [{ callback: () => setModal(false), icon: 'fas fa-times', text: 'Avbryt', bg: 'light' }],
            footer: [<Submit onSubmit={updateSubmit} text='Uppdatera' sending={updateSending} />]
          }}>
          <input type='hidden' {...updateRegister('lease_id')} value={id} />
          <Help title='Information' type='info'>
            <p>
              Här kan du skapa upp rader för tillägg och avdrag som gjorts. Detta kommer att användas vid uträkning av månadskostnad. Intervallet är detsamma
              som är angivet på uthyrningen
            </p>
            <p>
              Ett <b>tillägg</b> skapas genom att fylla i ett positivt värde
            </p>
            <p>
              Ett <b>avdrag</b> skapas genom att fylla i ett negativt värde
            </p>
          </Help>
          <div class='row mb-3'>
            <div class='row mb-3'>
              <Input controls={{ register: updateRegister }} name='name' options={{ required: true }} label='Namn' error={updateError} />
            </div>
            <div class='row mb-3'>
              <Input controls={{ register: updateRegister }} name='fee' options={{ required: true }} label='Belopp' type='number' error={updateError} />
            </div>
            <div class='row mb-3'>
              <Select
                controls={{ register: updateRegister }}
                name='tax_rate'
                options={{ required: true }}
                label='Momssats(%)'
                error={updateError}
                values={[
                  { key: '0%', value: 0 },
                  { key: '6%', value: 6 },
                  { key: '12%', value: 12 },
                  { key: '25%', value: 25 }
                ]}
              />
            </div>
            <div class='row'>
              <Date label='Startdatum för debitering' controls={{ control: updateControl }} name='start' error={updateError} />
            </div>
          </div>
        </Card>
      </Modal>
    </>
  )
}
