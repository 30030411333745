import React from 'react'
import { date } from '@/_helpers'
import { useGet, BeefyButton, Card, Output } from '@enkla-gui'

export default function EntityInfo({ entity }) {
  const { data: types } = useGet({ endpoint: 'api/entity/types' })

  const printTypeFields = () => {
    var fields = types?.filter(e => e.id === entity.entity_type_id)[0]?.fields || {}
    var output = []
    var bools = []
    for (let [key, value] of Object.entries(fields)) {
      switch (value.type) {
        case 'string':
        case 'number':
        case 'list':
          output.push(
            <div class='col-6 mb-3'>
              <Output value={entity.fields[key]} label={value.title} />
            </div>
          )
          break
        case 'boolean':
          bools.push(
            <div class='col-6'>
              <div class='mb-3 form-group'>
                <BeefyButton
                  _default={{
                    text: value.title,
                    icon: entity.fields[key] ? 'fa fa-check' : 'fa fa-times'
                  }}
                  _hover={{
                    text: value.title,
                    icon: entity.fields[key] ? 'fa fa-check' : 'fa fa-times'
                  }}
                  type={!entity.fields[key] ? 'info' : 'success'}
                  disabled
                />
              </div>
            </div>
          )
          break
        default:
          break
      }
    }
    return output.concat(bools)
  }

  return (
    <div class='component_EntityInfo'>
      <div class='row'>
        <div class='col'>
          <Card loading={!entity || !types} title={entity?.type?.name} icon={`fas ${entity?.type?.icon}`}>
            <div class='row mb-3'>
              <Output value={entity?.name} label='Namn' />
              <Output value={entity?.type?.name} label='Typ' />
            </div>
            <div class='row mb-3'>
              <Output value={entity?.address} label='Adress' />
              <Output value={entity?.postal_code} label='Postnummer' />
            </div>
            <div class='row mb-3'>
              <Output value={entity?.city} label='Stad' />
              <Output value={entity?.country} label='Land' />
            </div>
            <div class='row'>{entity?.fields && printTypeFields()}</div>
            <div class='row mb-3'>
              <div class='col-6'>
                <Output value={date.full(entity?.created_at)} label='Skapad' />
              </div>
              <div class='col-6'>
                <Output value={date.full(entity?.updated_at)} label='Uppdaterad' />
              </div>
            </div>
          </Card>
        </div>
      </div>
    </div>
  )
}
