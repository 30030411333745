import { authenticationService } from './authentication.service'
import { notify } from '../helpers/notify'

export default function handleResponse(response) {
  return response.json().then(data => {
    if (!response.ok) {
      if ([401, 403].indexOf(response.status) !== -1) {
        // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
        authenticationService.logout()
        if (window.location.hash !== '#!/login') window.location.reload(true)
      } else if (response.status === 402) {
        notify.toast('Du har inget aktivt medlemskap', 'warning')
        window.location.hash = '#!/setting/membership'
      } else if (response.status === 404) {
        window.location.hash = '#!/404'
      }
      return Promise.reject([response, data])
    } else {
      return data
    }
  })
}
