export const invoicebasehelper = {
  status(status) {
    if (status === undefined) return
    switch (status.toUpperCase()) {
      case 'EDIT':
        return {
          label: 'Redigeras',
          color: 'warning'
        }
      case 'READY':
        return {
          label: 'Redo att skickas',
          color: 'success'
        }
      case 'SENT':
        return {
          label: 'Skickad',
          color: 'warning'
        }
      case 'PARTIALLY_PAYED':
        return {
          label: 'Delbetald',
          color: 'warning'
        }
      case 'PAYED':
        return {
          label: 'Betald',
          color: 'success'
        }
      case 'OVERDUE':
        return {
          label: 'Förfallen',
          color: 'danger'
        }
      case 'CREDITED':
        return {
          label: 'Krediterad',
          color: 'light'
        }
      case 'CANCELLED':
        return {
          label: 'Makulerad',
          color: 'light'
        }
      case 'REVIEW':
        return {
          label: 'Attestering',
          color: 'warning'
        }
      case 'ERROR':
        return {
          label: 'Kräver åtgärd',
          color: 'danger'
        }
      default:
        break
    }
  }
}
