import React from 'react'
import { useParams } from 'react-router-dom'
import { ViewHeader, InfoBox, Table, Pagination, Card, useGet } from '@enkla-gui'

export default function Overview() {
  let { filter } = useParams()
  const getFilter = function () {
    switch (filter) {
      case 'active':
        return {
          title: 'Aktiva',
          filter: '/state/active'
        }
      case 'planned':
        return {
          title: 'Kommande',
          filter: '/state/planned'
        }
      case 'ending':
        return {
          title: 'Upphörande',
          filter: '/state/ending'
        }
      default:
        return {
          title: 'Alla',
          filter: ''
        }
    }
  }
  const { data: leases, setSorting, setPage, sorting, loading } = useGet({ endpoint: `api/lease${getFilter().filter}` })
  const { data: billable } = useGet({ endpoint: 'api/auth/billable' })

  return (
    <>
      <ViewHeader icon='fa-key' title='Uthyrning' subtitle={getFilter().title} />
      <div class='row'>
        <InfoBox loading={!leases} title='Uthyrda Objekt' subtitle={billable?.stat_leases} icon='fa-calendar-check' />
        <InfoBox loading={!leases} title='Upphörande Uthyrningar' subtitle={billable?.stat_inactive} icon='fa-calendar-times' />
        <InfoBox loading={!leases} title='Upphörande denna månad' subtitle={billable?.stat_ends_month} icon='fa-clock' />
      </div>
      <Card>
        <Table
          loadercount={15}
          sorting={sorting}
          fetching={loading}
          useSorting={sort => setSorting({ ...sort })}
          rows={leases?.data}
          empty={{
            text: `Det finns inte några ${getFilter().title === 'Alla' ? '' : getFilter().title.toLowerCase()} uthyrningar`,
            icon: 'fa-key'
          }}
          link={`/#!/lease/@{id}`}
          functions={{
            active: lease => <span class={`badge bg-${lease.state.color} w-100`}>{lease.state.title}</span>,
            getType: type => (
              <div class='icon-badge'>
                <i class={`fas fa-fw ${type.icon}`} /> <span>{type.name}</span>{' '}
              </div>
            ),
            entity: entity => (
              <a class='text-decoration-none' onClick={e => e.stopPropagation()} href={`/#!/entity/${entity.id}/`}>
                {entity.name}
              </a>
            ),
            getTenants: entity => entity.tenants.map(tenant => tenant.name + ' ')
          }}
          keys={['entity>function:entity', 'entity.type>function:getType', '_this>function:getTenants', '_this>function:active', 'created_at>date:short']}
          headers={[
            {
              value: 'Objekt',
              class: 'min'
            },
            {
              value: 'Typ',
              class: 'min'
            },
            {
              value: 'Hyresgäst'
            },
            {
              value: 'Status',
              class: 'min'
            },
            {
              key: 'created_at',
              value: 'Skapad',
              sortable: true,
              class: 'min'
            }
          ]}
        />
        <Pagination data={leases} fetch={setPage} pushToHistory />
      </Card>
    </>
  )
}
