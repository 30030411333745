import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { notify } from '@/_helpers'
import { API, ViewHeader, Card, Submit, Input, Select, Date, CheckBox, Help } from '@enkla-gui'

export default function LeaseEdit() {
  const { register, handleSubmit, reset, watch, control, getValues, setValue } = useForm(false)
  const [error, setError] = useState(null)
  const [sending, setSending] = useState(false)
  const { id } = useParams()
  const history = useHistory()

  useEffect(() => {
    API.get(`api/lease/${id}`).then(lease => {
      reset(lease)
    })
  }, [id])

  const onSubmit = data => {
    setSending(true)
    API.patch('api/lease', data)
      .then(res => {
        notify.toast('Ändringar sparade')
        setError(null)
        setSending(false)
        history.push(`/lease/${id}`)
      })
      .catch(([err, res]) => {
        setError({
          message: res.message,
          data: res.data
        })
        setSending(false)
      })
  }

  return (
    <>
      <ViewHeader
        loading={!watch('created_at')}
        title='Uthyrning'
        icon='fa-key'
        subtitle='Redigera'
        return={{ link: `#!/lease/${id}`, title: 'Tillbaka till Uthyrning' }}
        buttons={[{ icon: 'fa-times', title: 'Avbryt', href: `#!/lease/${id}`, position: 'right' }]}
      />
      <Card
        loading={!watch('created_at')}
        title='Uthyrning'
        icon='fa fa-fw fa-key'
        buttons={{
          footer: [<Submit onSubmit={handleSubmit(onSubmit)} text='Spara' sending={sending} />]
        }}>
        <div class='row mb-3'>
          <Input controls={{ register }} name='reference' options={{ required: false }} label='Avtalsreferens' error={error} />
          <Select
            controls={{ register }}
            name='payment_interval'
            options={{ required: true }}
            label='Betalningsintervall'
            tip='Angivet intervall hanteras som kalenderperiod'
            error={error}
            values={[
              { key: 'Månadsvis', value: 'MONTHLY' },
              { key: 'Kvartalsvis', value: 'QUARTERLY' },
              { key: 'Årligen', value: 'YEARLY' }
            ]}
          />
        </div>
        <div class='row mb-3'>
          <Input controls={{ register }} name='fee' options={{ required: true }} label='Avgift' type='number' error={error} />
          <div class='col' hidden={!getValues('entity.fields.area')}>
            <div class='form-group'>
              <label></label>
              <br />
              <CheckBox controls={{ register, getValues, setValue }} name={`fee_as_m2`} options={{ required: false }} label={'Pris per m2'} />
            </div>
          </div>
          <div class='mt-3' hidden={!watch('fee_as_m2')}>
            <Help temporary header={false} type='info'>
              <p>
                Objektet är <b>{getValues('entity.fields.area') || 0}m2</b>
              </p>
              <p>
                Avgiften blir <b>{watch('fee') * getValues('entity.fields.area')} SEK</b>
              </p>
            </Help>
          </div>
        </div>
        <div class='row mb-3'>
          <Select
            controls={{ register }}
            name='tax_rate'
            options={{ required: true }}
            label='Momssats(%)'
            error={error}
            values={[
              { key: '0%', value: 0 },
              { key: '6%', value: 6 },
              { key: '12%', value: 12 },
              { key: '25%', value: 25 }
            ]}
          />
        </div>
        <div class='row mb-3'>
          <Input controls={{ register }} name='notice_length' options={{ required: true }} label='Uppsägningstid' error={error} />
          <Select
            controls={{ register }}
            name='notice_type'
            options={{ required: true }}
            label='Enhet'
            error={error}
            values={[
              { key: 'Dag(ar)', value: 'DAY' },
              { key: 'Månad(er)', value: 'MONTH' },
              { key: 'År', value: 'YEAR' }
            ]}
          />
        </div>
        <div class='row mb-3'>
          <Date today label='Tillträde' controls={{ control }} name='start' error={error} placeholder='Välj ett datum, vid tomt värde anges dagens datum' />
          <Date label='Slutdatum' controls={{ control }} name='stop' error={error} />
        </div>
      </Card>
    </>
  )
}
