import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import Modal from 'react-modal'
import { notify, fasdokcasehelper } from '@/_helpers'
import { API, Card, Table, Submit, CoolChoice, Input } from '@enkla-gui'
import FasDokModal from './fasdokmodal.js'

export default function Agreements({ id }) {
  const { register, handleSubmit, reset } = useForm()
  const [agreements, setAgreements] = useState([])
  const [error, setError] = useState(false)
  const [sending, setSending] = useState(false)
  const [modal, setModal] = useState(false)
  const [fasDokModal, setFasDokModal] = useState(false)
  const [fileModal, setFileModal] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (id === undefined) return
    getAgreements()
  }, [id])

  const getAgreements = async () => {
    return await API.get(`api/agreement/lease/${id}`)
      .then(res => {
        setAgreements(res)
      })
      .catch(async ([err, res]) => {
        notify.alert('Det gick inte att hämta avtal', res.message, 'error')
      })
  }

  const remove = async agreement => {
    if (
      !(await notify.confirm(
        agreement.fasdokcase !== null
          ? 'Hyreskontraktet kommer även att tas bort från Fastighetsägarna Dokument'
          : 'Filen kommer även att tas bort från uthyrningen'
      ))
    )
      return
    setLoading(true)
    API.delete(`api/agreement/${agreement.id}`)
      .then(res => {
        notify.toast(`Hyreskontraktet har tagits bort`)
        getAgreements()
      })
      .catch(([err, res]) => {
        notify.toast(`Borttagningen misslyckades: ${res.message}`, 'error')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const download = agreement => {
    if (agreement.fasdokcase !== null) {
      downloadFasDokPDF(agreement.fasdokcase.case_id)
    } else {
      downloadFile(agreement.file_id)
    }
  }

  const downloadFile = id => {
    API.get(`api/file/${id}`)
      .then(res => {
        var link = document.createElement('a')
        link.href = res
        link.target = '_blank'
        link.click()
      })
      .catch(([err, res]) => {
        notify.toast(res.message, 'error')
        setSending(false)
        setError({
          message: res.message,
          data: res.data
        })
      })
  }

  const downloadFasDokPDF = case_id => {
    API.get(`api/integration/fasdok/${case_id}/pdf`)
      .then(res => {
        const linkSource = `data:application/pdf;base64,${res.file}`
        const downloadLink = document.createElement('a')
        const fileName = `Avtal_${case_id}.pdf`

        downloadLink.href = linkSource
        downloadLink.download = fileName
        downloadLink.click()
      })
      .catch(([err, res]) => {
        notify.toast(res.message, 'error')
      })
  }

  const create = (file, name) => {
    API.post('api/agreement', {
      name: name,
      file_id: file.id,
      lease_id: id
    })
      .then(res => {
        notify.toast('Hyreskontraktet har skapts')
        reset()
        setFileModal(false)
        getAgreements()
      })
      .catch(([err, res]) => {
        notify.toast(res.message, 'error')
      })
      .finally(() => {
        setSending(false)
      })
  }

  const upload = data => {
    setSending(true)
    var formData = new FormData()
    formData.append('file', data.file[0])
    formData.append('name', data.alias)
    formData.append('fileable_id', id)
    formData.append('fileable_type', 'lease')

    API.upload('api/file', formData)
      .then(res => {
        create(res.file, data.name)
      })
      .catch(([err, res]) => {
        notify.toast(res.message, 'error')
        setError({
          message: res.message,
          data: res.data
        })
        setSending(false)
      })
  }

  return (
    <>
      <Card
        loading={!agreements || loading}
        title='Hyreskontrakt'
        icon='fas fa-fw fa-gavel'
        buttons={{
          header: [
            <button class='btn btn-primary btn-sm text-light' onClick={() => setModal(true)}>
              <i class='fas text-light fa-plus-square' /> Skapa
            </button>
          ]
        }}>
        <Table
          rows={agreements}
          empty={{ text: 'Det finns inte några hyreskontrakt', icon: 'fas fa-gavel' }}
          functions={{
            remove: agreement => remove(agreement),
            download: agreement => download(agreement),
            signed: signed => <span class={`badge bg-${signed ? 'success' : 'danger'}`}>{signed ? 'Signerat' : 'Ej signerat'}</span>,
            type: agreement => <span class='badge bg-light'>{agreement.fasdokcase !== null ? 'Fastighetsägarna' : 'Fil'}</span>,
            status: agreement =>
              agreement.fasdokcase === null ? (
                ''
              ) : (
                <span class={`badge w-100 bg-${fasdokcasehelper.status(agreement.fasdokcase.status).color}`}>
                  {fasdokcasehelper.status(agreement.fasdokcase.status).label}
                </span>
              ),
            reference: agreement =>
              agreement.fasdokcase !== null ? (
                <a target='_blank' class='link§' href={`https://app.fastdok.se/contract-cases/${agreement.fasdokcase.case_id}`}>
                  {agreement.fasdokcase.case_id}
                </a>
              ) : (
                agreement.file.name
              )
          }}
          keys={[
            'name',
            '_this>function:type',
            '_this>function:reference',
            'created_at>date:full',
            '_this>buttons:download:fa-file-download:remove:fa-trash-alt'
          ]}>
          <th>Namn</th>
          <th class='min'>Typ</th>
          <th>Referens</th>
          <th>Skapad</th>
          <th class='min' />
        </Table>
      </Card>
      <FasDokModal
        id={id}
        close={update => {
          setFasDokModal(false)
          if (update) getAgreements()
        }}
        isOpen={fasDokModal}
      />
      <Modal isOpen={modal} onRequestClose={() => setModal(false)} className='no-padding'>
        <Card
          title='Skapa ett hyreskontrakt'
          icon='fas fa-fw fa-gavel'
          buttons={{
            header: [{ callback: () => setModal(false), icon: 'fas fa-times', text: 'Avbryt', bg: 'light' }]
          }}>
          <div class='row mb-3'>
            <div class='col'>
              <CoolChoice
                callback={() => {
                  setModal(false)
                  setFasDokModal(true)
                }}
                icon='fas fa-handshake'
                title='Fastighetsägarna Dokument'
                description='Har du valt att integrera Fastighetsägarna Dokument så kan du skapa upp ett hyreskontrakt med hjälp av deras mallar.'
              />
            </div>
          </div>
          <div class='row'>
            <div class='col'>
              <CoolChoice
                callback={() => {
                  setModal(false)
                  setFileModal(true)
                }}
                icon='fas fa-file-pdf'
                title='Ladda upp som fil'
                description='Har du ditt hyreskontrakt som fil så kan du välja att ladda upp den'
              />
            </div>
          </div>
        </Card>
      </Modal>
      <Modal isOpen={fileModal} onRequestClose={() => setFileModal(false)} className='no-padding'>
        <Card
          title='Ladda upp fil'
          icon='fas fa-fw fa-upload'
          buttons={{
            header: [{ callback: () => setFileModal(false), icon: 'fas fa-times', text: 'Avbryt', bg: 'light' }],
            footer: [<Submit onSubmit={handleSubmit(upload)} text='Ladda upp' sending={sending} />]
          }}>
          <div class='mb-3 form-group'>
            <label>Fil</label>
            <input {...register('file', { required: true })} class='form-control' type='file' name='file' />
            {error?.data?.file && (
              <div class='form-inline-error'>
                <i class='fa fa-fw fa-exclamation-triangle' /> {error.data.file.map(err => `${err}, `)}{' '}
              </div>
            )}
          </div>
          <div class='row mb-3'>
            <Input controls={{ register }} name='name' options={{ required: true }} label='Namn' error={error} />
          </div>
        </Card>
      </Modal>
    </>
  )
}
