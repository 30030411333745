import React from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { icons } from '@config'
import { ViewHeader, Table, Card, Output, useGet, useDelete } from '@enkla-gui'

export default function Addon() {
  let { id } = useParams()
  const history = useHistory()

  let { data: addon } = useGet({ endpoint: `api/addon/${id}` })
  let { remove } = useDelete({ endpoint: `api/addon/${id}`, success: () => history.push('/addon') })

  return (
    <>
      <ViewHeader
        loading={!addon}
        icon={icons.addon}
        subtitle={addon?.type}
        title={addon?.name}
        return={{ link: '#!/addon', title: 'Översikt' }}
        buttons={[
          { icon: icons.remove, title: 'Radera', callback: remove, position: 'right' },
          { icon: icons.edit, title: 'Redigera', href: `#!/addon/${addon?.id}/edit`, position: 'right' }
        ]}
      />
      <Card loading={!addon} title={addon?.name} icon={icons.addon}>
        <div class='row mb-3'>
          <Output value={addon?.name} label='Namn' cols='col-4' />
          <Output value={addon?.fee} label='Belopp' cols='col-4' type='currency' />
          <Output value={`${addon?.tax_rate}%`} label='Momssats' cols='col-4' />
        </div>
      </Card>
      <Card title={`Uthyrningar som använder detta ${addon?.type?.toLowerCase()}`} icon='fas fa-key'>
        <Table
          rows={addon?.leases}
          link={`/#!/lease/@{id}`}
          empty={{
            text: `Detta ${addon?.type?.toLowerCase()} används inte än`,
            icon: icons.addon
          }}
          functions={{
            active: lease => <span class={`badge bg-${lease.state.color} w-100`}>{lease.state.title}</span>,
            getType: type => (
              <div class='icon-badge'>
                <i class={`fas fa-fw ${type.icon}`} /> <span>{type.name}</span>{' '}
              </div>
            )
          }}
          keys={['entity.name', 'entity.type>function:getType', '_this>function:active']}>
          <th>Uthyrning</th>
          <th class='min'>Typ</th>
          <th class='min'>Status</th>
        </Table>
      </Card>
    </>
  )
}
