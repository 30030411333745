import { useState, useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { API, notify } from '@enkla-gui'

export const useSubmit = ({ endpoint, success = false, confirm = false, failed = false, method = 'post', form = {} }) => {
  const [modal, setModal] = useState(null)
  const [loading, setLoading] = useState(null)
  const [error, setError] = useState(null)

  const { register, handleSubmit, reset, control, setValue, getValues, watch } = useForm()
  const formRef = useRef()

  useEffect(() => {
    //xD
    if (JSON.stringify(form) !== JSON.stringify(formRef.current)) {
      reset(form)
      formRef.current = form
    }
  }, [reset, form])

  const post = async payload => {
    if (confirm) {
      if (!(await notify.confirm(confirm))) return
    }
    setLoading(true)
    API[method](endpoint, payload)
      .then(res => {
        notify.toast(res.message)
        setLoading(false)
        setModal(false)
        setError(false)
        if (success) success(res)
      })
      .catch(([err, res]) => {
        notify.toast(res.message, 'error')
        setError(res)
        setLoading(false)
        if (failed) failed([err, res])
      })
  }

  return {
    modal,
    closeModal: () => setModal(false),
    setModal,
    loading,
    error,
    handleSubmit,
    post: post,
    submit: handleSubmit(post),
    reset,
    control,
    setValue,
    register,
    getValues,
    watch
  }
}
