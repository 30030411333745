import { useState } from 'react'
import { API, notify } from '@enkla-gui'

export const useDelete = ({ endpoint, confirm, success = false, failed = false }) => {
  const [modal, setModal] = useState(null)
  const [loading, setLoading] = useState(null)
  const [error, setError] = useState(null)

  //LMFAO
  const remove = async (appendpoint = '') => {
    if (!(await notify.confirm(confirm))) return
    setLoading(true)
    API.delete(endpoint + appendpoint)
      .then(res => {
        notify.toast(res.message)
        setLoading(false)
        setModal(false)
        if (success) success(res)
      })
      .catch(([err, res]) => {
        notify.toast(res.message, 'error')
        setError(err)
        setLoading(false)
        if (failed) failed([err, res])
      })
  }

  return { modal, setModal, loading, error, remove: remove, closeModal: () => setModal(false) }
}
