import React, { useState, useEffect } from 'react'
import { BehaviorSubject } from 'rxjs'
import { date } from '@enkla-gui/helpers'
const closeAllDropdowns = new BehaviorSubject(false)

export default function Dropdown({ title, options, sending = false, icon = 'fa-chevron-down' }) {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    closeAllDropdowns.subscribe(x => setOpen(false))
  }, [])

  const toggle = () => {
    if (sending) return
    closeAllDropdowns.next(date.now())
    setOpen(!open)
  }

  const handle = option => {
    if (option.disabled) return
    setOpen(false)
    option.callback()
  }

  return (
    <div class='component_Dropdown' style={{ marginRight: '10px' }}>
      <button class={`btn btn-gray ${sending && 'disabled'}`} onClick={() => toggle()}>
        <i class={`fas fa-fw ${sending ? 'fa-circle-notch fa-spin' : icon}`} />
        {title}
      </button>
      <ul class='dropdown-list card' hidden={!open}>
        {options.map(option => (
          <li>
            <button class={`dropdown-item ${option.disabled && 'dropdown-item-disabled'}`} onClick={() => handle(option)} disabled={option.disabled}>
              <i class={`fas ${option.icon}`} /> {option.title}
            </button>
          </li>
        ))}
      </ul>
    </div>
  )
}
