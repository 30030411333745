import React, { useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { notify } from '@/_helpers'
import { API, ViewHeader, CheckBox, Card, Submit, Input } from '@enkla-gui'

export default function CreateTenant() {
  const { register, handleSubmit, getValues, setValue } = useForm()
  const [error, setError] = useState(null)
  const [sending, setSending] = useState(false)
  const { id } = useParams()
  const history = useHistory()

  const onSubmit = data => {
    setSending(true)
    API.post('api/tenant', data)
      .then(res => {
        notify.toast(`${res.tenant.name} tillagd.`)
        history.push(`/lease/${id}`)
      })
      .catch(([err, res]) => {
        setError({
          message: res.message,
          data: res.data
        })
        setSending(false)
      })
  }
  return (
    <>
      <ViewHeader
        title='Uthyrning'
        icon='fa-address-card'
        subtitle='Lägg till Hyresgäst'
        return={{ title: 'Tillbaka till Uthyrning', link: `#!/lease/${id}` }}
      />
      <Card
        title='Hyresgäst'
        icon='fa fa-fw fa-address-book'
        buttons={{
          footer: [<Submit onSubmit={handleSubmit(onSubmit)} text='Skapa' sending={sending} />]
        }}>
        <input type='hidden' {...register('lease_id')} value={id} />
        <div class='row'>
          <div class='mb-3 form-group'>
            <label>Företagskund</label>
            <br />
            <CheckBox controls={{ register, getValues, setValue }} name={`organisation`} options={{ required: false }} label={'Företagskund'} />
          </div>
        </div>
        <div class='row mb-3'>
          <Input controls={{ register }} name='name' options={{ required: true }} label='Namn' error={error} />
          <Input controls={{ register }} name='identifier' options={{ required: true }} label='Personnummer / Organisationsnummer' error={error} />
        </div>
        <div class='row mb-3'>
          <Input controls={{ register }} name='phone' options={{ required: true }} label='Telefonnummer' error={error} />
          <Input controls={{ register }} name='email' options={{ required: true }} label='E-post' error={error} />
        </div>
      </Card>
    </>
  )
}
