import React, { useState } from 'react'

import { API } from '@enkla-gui'
import { notify } from '@/_helpers'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

export default function Reset() {
  const { register, handleSubmit } = useForm()
  const [error, setError] = useState(null)
  let { token } = useParams()

  var onSubmit = data => {
    data.token = token
    API.post('api/auth/reset', data)
      .then(res => {
        notify.toast('Lösenordet har återställts')
        window.location.hash = '/'
      })
      .catch(([error, res]) => {
        setError({
          message: res.message,
          data: res.data
        })
      })
  }
  return (
    <div class='full-height login-wrapper'>
      <div class='col bicflex'>
        <div class='user-form'>
          <form onSubmit={handleSubmit(onSubmit)} class='user'>
            <div class='row'>
              <div class='col-md p-5 pb-0'>
                <div class='row'></div>
                <div class='row mb-3'>
                  <div className='col'>
                    {' '}
                    <img width='250' src={`${process.env.PUBLIC_URL}/images/logo-black.svg`} />
                    <h4 class='text'>
                      Välkommen till Enkla Fastigheter! <span class=' wave'>👋</span>
                    </h4>
                    <p class='text-divider'>
                      <span>Återställ lösenord</span>
                    </p>
                  </div>
                </div>

                <div class='col-md mb-3'>
                  {error?.message && <div class='alert alert-danger'>{error.message}</div>}
                  <div class='mb-3'>
                    <label>E-post</label>
                    <input {...register('email', { required: true })} type='text' class='form-control form-control-register' />
                    {error?.data?.email && <div class='form-inline-error'>{error.data.email[0]} </div>}
                  </div>
                  <div class='mb-3'>
                    <label>Lösenord</label>
                    <input {...register('password')} type='password' class='form-control form-control-register' />
                    {error?.data?.password && <div class='form-inline-error'>{error.data.password[0]} </div>}
                  </div>
                  <div class='mb-3'>
                    <label>Verifiera Lösenord</label>
                    <input {...register('password_confirmation')} type='password' class='form-control form-control-register' />
                    {error?.data?.password_confirm && <div class='form-inline-error'>{error.data.password_confirm[0]} </div>}
                  </div>
                  <div class='d-grid gap-2 mb-1'>
                    <button type='submit' class='btn register-button'>
                      Återställ Lösenord
                    </button>
                  </div>
                </div>
                <div class='mb-5'>
                  <span class='small text pull-right'>
                    <a class='link' href='#/login'>
                      Tillbaka till inloggning
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class='col d-none d-md-block bicflex login-hero divider'>
        <div class='login-hero-content bicflex'>
          <img style={{ width: '75%', display: 'block' }} src={`${process.env.PUBLIC_URL}/images/forgot_password.svg`} />
        </div>
      </div>
    </div>
  )
}
