import React from 'react'

export default function Help({ children, title = 'Information', type = 'warning', icon = 'fas fa-lightbulb', header = true, temporary = false }) {
  return (
    <div class='component_Help'>
      <div class={`card card-${type} ${temporary ? 'temporary' : ''}`}>
        {header && (
          <div class='card-header'>
            <div class='card-header-text'>
              <i class={icon} />
              <span>{title}</span>
            </div>
          </div>
        )}
        <div class='card-body'>
          <i class='fas fa-bells' />
          {children}
        </div>
      </div>
    </div>
  )
}
