import React, { useState, useEffect, useContext } from 'react'
import { ViewHeader, API } from '@enkla-gui'
import { UserContext } from '@/App'

export default function Greeting({ greeting = false }) {
  const { user } = useContext(UserContext)
  const [notifications, setNotifications] = useState({})
  useEffect(() => {
    API.get('api/auth/notifications').then(notifications => setNotifications(notifications))
  }, [])
  return (
    <div class='component_Greeting'>
      <ViewHeader
        loading={Object.keys(user).length === 0}
        title={
          <div class='col'>
            <h2 class='text'>
              <span class='d-inline-block me-2'>👋</span>
              Hej, {user?.name}!
            </h2>
          </div>
        }
        subtitle={greeting ? greeting : notifications.unread ? `Du har olästa notifikationer, läs dom uppe till höger.` : `Välkommen till Enkla Fastigheter.`}
      />
    </div>
  )
}
