
export const fasdokcasehelper = {
	status(status) {
		switch (status) {
			case 'DRAFT':
				return {
					label: 'Utkast',
					color: 'warning'
				}
			case 'DONE':
				return {
					label: 'Klart',
					color: 'success'
				}
			case 'CONCLUDED':
				return {
					label: 'Avslutat',
					color: 'light'
				}
			default:
				break
		}
	}
}
