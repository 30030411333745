import React from 'react'
import { date, maintenancehelper } from '@/_helpers'
import { useGet, BeefyButton, Card, Table, Pagination } from '@enkla-gui'
import { icons } from '@config'
export default function MaintenanceTable({ id, type }) {
  const { data: activities, setSorting, sorting, setPage } = useGet({ endpoint: `api/maintenance`, filter: { [`${type}_id`]: id } })
  return (
    <div class='component_MaintenanceTable'>
      <div class='row'>
        <div class='col'>
          <Card
            title='Teknisk förvaltning'
            icon={icons.maintenance}
            buttons={{
              header: [{ link: `#!/${type}/${id}/maintenance/create`, icon: 'fas fa-plus-square text-light', text: 'Ny aktivitet' }]
            }}>
            <Table
              rows={activities?.data}
              link={`/#!/maintenance/@{id}`}
              functions={{
                status: status => maintenancehelper.statusBadge(status)
              }}
              sorting={sorting}
              useSorting={sort => setSorting({ ...sort })}
              empty={{ text: 'Det finns inte några förvaltningsaktiviteter', icon: icons.maintenance }}
              keys={['title', 'status>function:status', 'completion', 'created_at>date:short']}
              headers={[
                {
                  value: 'Aktivitet',
                  sortable: true,
                  key: 'title'
                },
                {
                  value: 'Status',
                  sortable: true,
                  class: 'min',
                  key: 'status'
                },
                {
                  value: 'Avklarade uppgifter',
                  class: 'min',
                  key: 'completion'
                },
                {
                  key: 'created_at',
                  value: 'Skapad',
                  sortable: true,
                  class: 'min'
                }
              ]}
            />
            <Pagination data={activities} fetch={setPage} />
          </Card>
        </div>
      </div>
    </div>
  )
}
