import React from 'react'
import { useHistory } from 'react-router-dom'
import { ViewHeader, Card, Submit, Input, Select, useSubmit } from '@enkla-gui'
import { icons } from '@config'

export default function AddonCreate() {
  const history = useHistory()
  const { register, submit, error, loading } = useSubmit({ endpoint: 'api/addon', success: res => history.push(`/addon/${res.addon.id}`) })

  return (
    <>
      <ViewHeader title='Tillägg & Avdrag' icon={icons.addon} subtitle='Skapa' return={{ link: '#!/addon', title: 'Översikt' }} />
      <Card
        buttons={{
          footer: [<Submit class='guide-step-3' onSubmit={submit} text='Skapa' sending={loading} />]
        }}>
        <div class='row mb-3'>
          <Input controls={{ register }} name='name' options={{ required: true }} label='Namn' error={error} />
        </div>
        <div class='row mb-3'>
          <Input
            controls={{ register }}
            name='fee'
            options={{ required: true }}
            type='number'
            label='Belopp'
            error={error}
            tip='Ett tillägg skapas genom att ange ett värde större än 0. Ett avdrag skapas genom att ange ett värde mindre än 0'
          />
        </div>
        <div class='row mb-3'>
          <Select
            controls={{ register }}
            name='tax_rate'
            options={{ required: true }}
            label='Momssats(%)'
            error={error}
            values={[
              { key: '0%', value: 0 },
              { key: '6%', value: 6 },
              { key: '12%', value: 12 },
              { key: '25%', value: 25 }
            ]}
          />
        </div>
      </Card>
    </>
  )
}
