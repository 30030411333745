import React from 'react'
import { PageLoader } from '@enkla-gui'

export const loader = {
  text: function (...text) {
    if (text[0] === undefined) return <i class='fas fa-circle-notch fa-spin' />
    return text.join('')
  },
  spinner: function () {
    return <i class='fas fa-circle-notch fa-spin' />
  },
  content: function () {
    return (
      <div class='full-parent-spinner d-flex justify-content-center align-items-center h-100  m-5'>
        <i class='fas fa-circle-notch fa-spin fa-2x' />
      </div>
    )
  },
  page: function () {
    return <PageLoader />
  }
}
