import React from 'react'

export default function NoTengo() {
  return (
    <div class='row notengo-content'>
      <div class='notengo-wrapper'>
        <i class='fas fa-fw fa-hiking'></i>
        <span>404</span>
      </div>
    </div>
  )
}
