import React, { useState, useContext } from 'react'
import { notify } from '@/_helpers'
import { API, ViewHeader, Card, Submit, Select, TextArea } from '@enkla-gui'
import { useForm } from 'react-hook-form'
import { UserContext } from '@/App'

export default function HelpContact() {
  const { register, handleSubmit, setValue, watch, reset } = useForm()
  const [sending, setSending] = useState(false)
  const [error, setError] = useState(false)
  const { user } = useContext(UserContext)

  const send = data => {
    setSending(true)
    API.post('api/billable/help', data)
      .then(res => {
        notify.alert(`${data.type} skickad.`, `Vi kommer att svara på ditt meddelande via e-post (${user.email})`)
        setSending(false)
        reset({
          type: 'Fråga',
          body: '',
          reproduce: ''
        })
      })
      .catch(([err, res]) => {
        setError({
          message: res.message,
          data: res.data
        })
        setSending(false)
      })
  }

  return (
    <>
      <ViewHeader icon='fa-life-ring' title='Hjälp' subtitle='Kontakta oss' />
      <div class='row'>
        <Card
          title='Kontakta oss'
          icon='fas fa-paper-plane'
          buttons={{
            footer: [<Submit onSubmit={handleSubmit(send)} text='Skicka' sending={sending} />]
          }}>
          <div class='row mb-3'>
            <Select
              controls={{ register, setValue }}
              name='type'
              options={{ required: true }}
              label='Typ'
              error={error}
              values={[
                { key: 'Fråga', value: 'Fråga' },
                { key: 'Funktionsförfrågan', value: 'Funktionsförfrågan' },
                { key: 'Förbättringsförslag', value: 'Förbättringsförslag' },
                { key: 'Felanmälan', value: 'Felanmälan' }
              ]}
            />
          </div>
          <div class='row mb-3'>
            <TextArea controls={{ register }} name='body' options={{ required: true }} label='Innehåll' error={error} />
          </div>
          {watch('type') === 'Felanmälan' && (
            <div class='row mb-3'>
              <TextArea
                controls={{ register }}
                name='reproduce'
                options={{ required: true }}
                label='Beskriv steg för steg vad du har försökt göra och vad som hänt'
                error={error}
              />
            </div>
          )}
        </Card>
      </div>
    </>
  )
}
