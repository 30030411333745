const icons = {
  addon: 'fas fa-receipt',
  maintenance: 'fas fa-wrench',
  address: 'fas fa-map-marker-alt',
  company: 'fas fa-briefcase',
  entity: 'fas fa-layer-group',
  tenant: 'fas fa-address-card',
  loading: 'fas fa-circle-notch fa-spin',
  hgp: 'fas fa-id-badge',
  file: 'fas fa-server',
  download: 'fas fa-download',
  remove: 'fas fa-trash-alt',
  send: 'fas fa-paper-plane',
  email: 'fas fa-envelope',
  edit: 'fas fa-edit',
  lease: 'fas fa-key',
  phone: 'fas fa-phone',
  play: 'fas fa-play',
  link: 'fas fa-link',
  money: 'fa-money-check-alt',
  ticket: 'fas fa-envelope-open-text',
  create: 'fas fa-plus-square',
  cancel: 'fas fa-times',
  info: 'fas fa-info-circle',
  inbox: 'fas fa-inbox',
  vacancies: 'fas fa-door-open',
  tree: 'far fa-tree',
  tasks: 'fas fa-tasks',
  clock: 'fas fa-clock',
  check: 'fas fa-check',
  ban: 'fas fa-ban',
  warning: 'fas fa-exclamation-triangle',
  invoice: 'fas fa-file-invoice',
  restore: 'fas fa-sync',
  toggle: value => (value ? 'fas fa-check' : 'fas fa-times')
}

export { icons }
