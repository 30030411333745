import { toast as reactToastify } from 'react-toastify'
import Swal from 'sweetalert2'

export const notify = {
  confirm: async function (message) {
    return await Swal.fire({
      title: message ? 'Är du säker?' : '',
      text: message || 'Är du säker?',
      icon: 'question',
      confirmButtonText: 'Fortsätt',
      showCancelButton: true,
      iconColor: '#777',
      customClass: {
        cancelButton: 'text-dark'
      },
      cancelButtonText: 'Avbryt',
      cancelButtonColor: '#eff2f7',
      confirmButtonColor: '#3742fa',
      cancelButtonTextColor: '#000'
    }).then(response => {
      return response.isConfirmed
    })
  },
  request: async function (message, callback, buttonText = 'Fortsätt') {
    return await Swal.fire({
      text: message || 'Är du säker?',
      icon: 'question',
      confirmButtonText: buttonText,
      showCancelButton: true,
      iconColor: '#777',
      customClass: {
        cancelButton: 'text-dark'
      },
      cancelButtonText: 'Avbryt',
      cancelButtonColor: '#eff2f7',
      confirmButtonColor: '#3742fa',
      cancelButtonTextColor: '#000',
      showLoaderOnConfirm: true,
      preConfirm: async () => await callback(),
      allowOutsideClick: () => !Swal.isLoading()
    })
  },
  toast: function (message, type) {
    if (type === undefined || typeof reactToastify[type] !== 'function') return reactToastify.success(message)
    return reactToastify[type](message)
  },
  alert: function (title, message, type = 'success') {
    return Swal.fire(
      title,
      message,
      type
    )
  }
}
