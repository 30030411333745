import React from 'react'
import { ViewHeader, Card, Pagination, Table, useGet, Filter } from '@enkla-gui'
import { icons, filters } from '@config'
import { maintenancehelper } from '@/_helpers'
export default function MaintenanceOverview() {
  const { data: activities, setSorting, sorting, setPage, loading, setFilter } = useGet({ endpoint: `api/maintenance`, filter: { limit: 15 } })
  return (
    <>
      <ViewHeader title='Förvaltningsaktiviteter' subtitle='Översikt' icon={icons.maintenance} />
      <Filter filters={filters.maintenance} useFilter={_filter => setFilter({ ..._filter })} />
      <Card>
        <Table
          rows={activities?.data}
          link={`/#!/maintenance/@{id}`}
          fetching={loading}
          sorting={sorting}
          empty={{
            text: 'Det finns inga aktiviteter',
            icon: icons.maintenance
          }}
          functions={{
            linked: linked => (
              <a class='text-decoration-none' onClick={e => e.stopPropagation()} href={`/#!/${linked.type}/${linked.id}/`}>
                {linked.name}
              </a>
            ),
            status: status => maintenancehelper.statusBadge(status)
          }}
          keys={['title', 'linked>function:linked', 'status>function:status', 'completion', 'created_at>date:short']}
          useSorting={sort => setSorting({ ...sort })}
          headers={[
            {
              value: 'Aktivitet',
              sortable: true,
              key: 'title'
            },
            {
              value: 'Avser objekt/fastighet',
              key: 'linked'
            },
            {
              value: 'Status',
              sortable: true,
              class: 'min',
              key: 'status'
            },

            {
              value: 'Avklarade uppgifter',
              class: 'min',
              key: 'completion'
            },
            {
              key: 'created_at',
              value: 'Skapad',
              sortable: true,
              class: 'min'
            }
          ]}
        />
        <Pagination data={activities} fetch={setPage} />
      </Card>
    </>
  )
}
