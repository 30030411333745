import React from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { ViewHeader, Table, Card, Output, useGet, useDelete } from '@enkla-gui'
import MaintenanceTasks from '@components/maintenance/MaintenanceTasks'
import { icons } from '@config'
import { maintenancehelper } from '@/_helpers'
import { BehaviorSubject } from 'rxjs'

export default function TemplateView() {
  let { id } = useParams()
  const history = useHistory()

  let { data: template } = useGet({ endpoint: `api/maintenance/template/${id}` })

  let { remove } = useDelete({
    endpoint: `api/maintenance/template/${id}`,
    success: () => history.push('/maintenance/template/overview')
  })

  return (
    <>
      <ViewHeader
        loading={!template}
        icon={icons.maintenance}
        title={'Rutin'}
        subtitle={template?.title}
        return={{ link: '#!/maintenance/template/overview', title: 'Översikt' }}
        buttons={[
          { icon: icons.remove, title: 'Radera', callback: remove, position: 'right' },
          { icon: icons.edit, title: 'Redigera', href: `#!/maintenance/template/${template?.id}/edit`, position: 'right' }
        ]}
      />
      <Card loading={!template}>
        <div class='row'>
          <div class='col'>
            <div class='form-group'>
              <textarea
                rows={10}
                disabled
                type='text'
                style={{ background: 'rgb(248, 248, 251)' }}
                class='form-control form-control-input '
                value={template?.description}
              />
              <label class='label-control'>Beskrivning</label>
            </div>
          </div>
        </div>
      </Card>
      {template?.tasks && (
        <Card title='Uppgifter' icon={icons.tasks}>
          <MaintenanceTasks tasks={new BehaviorSubject(template.tasks)} previewMode />
        </Card>
      )}
      <Card title='Aktiviteter som använder denna rutin' icon={icons.maintenance}>
        <Table
          rows={template?.activities}
          link={`/#!/maintenance/@{id}`}
          functions={{
            linked: linked => (
              <a class='text-decoration-none' onClick={e => e.stopPropagation()} href={`/#!/${linked.type}/${linked.id}/`}>
                {linked.name}
              </a>
            ),
            status: status => maintenancehelper.statusBadge(status)
          }}
          empty={{ text: 'Det finns inte några förvaltningsaktiviteter', icon: icons.maintenance }}
          keys={['title', 'linked>function:linked', 'status>function:status', 'completion', 'created_at>date:short']}>
          <th>Aktivitet</th>
          <th>Avser objekt/fastighet</th>
          <th>Status</th>
          <th>Avklarade uppgifter</th>
          <th>Skapad</th>
        </Table>
      </Card>
    </>
  )
}
