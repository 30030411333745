import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { date, invoicebasehelper } from '@/_helpers'
import { API, Card, Table, Pagination } from '@enkla-gui'

export default function TenantInvoices(props) {
  const [invoicebases, setInvoicebases] = useState([])
  const [sorting, setSorting] = useState({})
  let { page } = useParams()

  useEffect(() => {
    if (props.id === undefined) return
    fetchInvoicebases()
  }, [props.id, sorting])

  const fetchInvoicebases = async (p = page || 1) => {
    API.get(`api/invoicebase/tenant/${props.id}`, {
      page: p,
      ...sorting
    }).then(invoicebases => {
      setInvoicebases(invoicebases)
    })
  }

  return (
    <>
      <Card title='Hyresavier' icon='fas fa-file-invoice'>
        <Table
          rows={invoicebases.data}
          empty={{ text: 'Det finns inte några hyresavier', icon: 'fas fa-file-invoice' }}
          sorting={sorting}
          useSorting={sort => setSorting({ ...sort })}
          link={`/#!/invoicebase/@{id}`}
          functions={{
            status: status => <span class={`badge w-100 bg-${invoicebasehelper.status(status).color}`}>{invoicebasehelper.status(status).label}</span>,
            period: invoice => date.short(invoice.start) + ' - ' + date.short(invoice.stop),
            lease: invoice => (
              <a onClick={e => e.stopPropagation()} href={`/#!/lease/${invoice.lease.id}`}>
                {invoice.lease.entity.name}
              </a>
            ),
            reference: ref => (ref === null ? 'Ej skapad' : ref)
          }}
          keys={['reference>function:reference', 'status>function:status', 'title', 'payment_left>currency', '_this>function:period', '_this>function:lease']}
          headers={[
            {
              value: 'Referens',
              class: 'min'
            },
            {
              key: 'status',
              value: 'Status',
              sortable: true,
              class: 'min'
            },
            {
              key: 'invoice_type',
              value: 'Typ',
              sortable: true,
              class: 'min'
            },
            {
              key: 'payment_left',
              sortable: true,
              value: 'Kvar att betala'
            },
            {
              key: 'stop',
              sortable: true,
              value: 'Period'
            },
            {
              key: '',
              value: 'Uthyrning'
            }
          ]}
        />
        <Pagination data={invoicebases} fetch={fetchInvoicebases} />
      </Card>
    </>
  )
}
