import React from 'react'
import { useHistory } from 'react-router-dom'
import { ViewHeader, Card, Submit, Input, Help, useSubmit, useGet } from '@enkla-gui'
import { icons } from '@config'

export default function CompanyCreate() {
  const history = useHistory()
  const { data: companies } = useGet({ endpoint: 'api/company' })
  const { register, error, loading, submit } = useSubmit({ endpoint: 'api/company', success: res => history.push(`/company/${res.company.id}`) })

  return (
    <>
      <ViewHeader loading={!companies} title='Företag' icon={icons.company} subtitle='Skapa' return={{ link: '#!/company', title: 'Företagsöversikt' }} />
      {companies?.total === 0 && (
        <Help title='Skapa ditt första företag' type='info'>
          <span>
            Systemet använder sig av företag som bas för hyresavisering och tillåter att du sätter upp ett eller flera företag beroende på hur just din
            verksamhet ser ut.
          </span>
          <br />
          <span></span>
        </Help>
      )}
      <Card
        loading={!companies}
        buttons={{
          footer: [<Submit class='guide-step-3' onSubmit={submit} text='Skapa' sending={loading} />]
        }}>
        <div class='row mb-3 guide-step-1'>
          <Input controls={{ register }} name='company.name' options={{ required: true }} label='Namn' error={error} />
          <Input controls={{ register }} name='company.organisation_number' options={{ required: true }} label='Organisationsnummer' error={error} />
        </div>
        <div class='row mb-3 guide-step-2'>
          <Input controls={{ register }} name='company.address' options={{ required: true }} label='Adress' error={error} />
          <Input controls={{ register }} name='company.postal_code' options={{ required: true }} label='Postnummer' error={error} />
        </div>
        <div class='row mb-3'>
          <Input controls={{ register }} name='company.city' options={{ required: true }} label='Stad' error={error} />
          <Input controls={{ register }} name='company.country' options={{ required: true }} label='Land' error={error} />
        </div>
        <div class='row'>
          <Input controls={{ register }} name='company.phone' options={{ required: false }} label='Telefonnummer' error={error} />
          <Input controls={{ register }} name='company.email' options={{ required: false }} label='Epost-adress' error={error} />
        </div>
      </Card>
    </>
  )
}
