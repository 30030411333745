import React from 'react'

export default function CoolChoice({ icon, title, description, callback }) {
	return (
		<div class='component_CoolChoice' onClick={() => callback()}>
			<div class='cool-choice'>
				<div class='icon'>
					<i class={`fa-fw ${icon}`} />
				</div>
				<div class='text'>
					<span class='title'>{title}</span>
					<span class='description'>{description}</span>
				</div>
			</div>
		</div>
	)
}
