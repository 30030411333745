import { useState } from 'react'
import { API, notify } from '@enkla-gui'

export const usePost = ({ success = false, failed = false, confirm = false } = {}) => {
  const [modal, setModal] = useState(null)
  const [loading, setLoading] = useState(null)
  const [error, setError] = useState(null)

  const post = async ({ endpoint, payload = {} }) => {
    if (confirm) {
      if (!(await notify.confirm(confirm))) return
    }
    setLoading(true)
    API.post(endpoint, payload)
      .then(res => {
        notify.toast(res.message)
        setLoading(false)
        setModal(false)
        if (success) success(res)
      })
      .catch(([err, res]) => {
        notify.toast(res?.message ?? 'Något gick fel', 'error')
        setError(err)
        setLoading(false)
        if (failed) failed([err, res])
      })
  }

  return { modal, setModal, loading, error, post }
}
