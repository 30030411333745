import React, { useContext } from 'react'
import { UserContext } from '@/App'
import { API, ViewHeader, notify, Table, Pagination, Card, TextArea, Input, Submit, useSubmit, useGet, useDelete } from '@enkla-gui'
import Modal from 'react-modal'
import { icons } from '@config'

export default function Files() {
  const { billable } = useContext(UserContext)
  const { data: files, sorting, setSorting, fetch, setPage } = useGet({ endpoint: 'api/file' })
  const { register, submit, reset, modal, setModal, loading, getValues, error } = useSubmit({ endpoint: 'api/file', method: 'patch', success: fetch })
  const { remove } = useDelete({
    endpoint: 'api/file/',
    success: () => {
      fetch()
      setModal(false)
    }
  })

  const download = file => {
    API.get(`api/file/${file.id}`, {})
      .then(res => {
        var link = document.createElement('a')
        link.href = res
        link.target = '_blank'
        link.click()
      })
      .catch(([err, res]) => {
        notify.toast(res.message, 'error')
      })
  }

  return (
    <>
      <ViewHeader
        loading={!billable.storage}
        icon={icons.file}
        title='Filer'
        subtitle='Översikt'
        badges={[{ bg: 'danger', text: 'Du börjar få ont om lagringsutrymme', hidden: billable?.storage?.percentage < 90 }]}
      />

      <Card>
        <Table
          rows={files?.data}
          empty={{
            text: 'Det finns inte några filer',
            icon: icons.file
          }}
          functions={{
            links: file =>
              file.orphan !== null ? (
                <span class='text-muted'>{file.related}</span>
              ) : (
                <a onClick={e => e.stopPropagation()} href={`/#!/${file.fileable_type}/${file.fileable.id}`}>
                  {(() => {
                    switch (file.fileable_type) {
                      case 'lease':
                        return 'Uthyrning - ' + file.related
                      case 'maintenance':
                        return 'Förvaltningsaktivitet - ' + (file.fileable.hasOwnProperty('entity') ? file.fileable.entity.name : file.fileable.company.name)
                      default:
                        return file.fileable.name
                    }
                  })()}
                </a>
              ),
            show: file => {
              reset(file)
              setModal(true)
            },
            download: file => download(file)
          }}
          sorting={sorting}
          useSorting={sort => setSorting({ ...sort })}
          keys={[
            `_this>button:show:${icons.edit}`,
            '_this>function:links',
            'name',
            'notice',
            'size',
            'created_at>date:full',
            `_this>button:download:${icons.download}`
          ]}
          headers={[
            {
              value: '',
              class: 'min'
            },
            {
              value: 'Kopplad till'
            },
            {
              key: 'name',
              value: 'Filnamn',
              sortable: true
            },
            {
              key: 'notice',
              value: 'Beskrivning',
              sortable: true
            },
            {
              key: 'size',
              value: 'Storlek',
              sortable: true
            },
            {
              key: 'created_at',
              value: 'Skapad',
              sortable: true
            },
            {
              value: '',
              class: 'min'
            }
          ]}
        />
        <Pagination data={files} fetch={setPage} pushToHistory />
      </Card>
      <Modal isOpen={modal} onRequestClose={() => setModal(false)} className='no-padding'>
        <Card
          title='Fil'
          icon='fas fa-fw fa-server'
          buttons={{
            header: [{ callback: () => setModal(false), icon: icons.remove, text: 'Avbryt', bg: 'light' }],
            footer: [
              {
                callback: () => remove(getValues('id')),
                icon: icons.remove,
                text: 'Radera',
                bg: 'danger text-white'
              },
              <Submit onSubmit={submit} text='Uppdatera' sending={loading} />
            ]
          }}>
          <div class='mb-3'>
            <Input controls={{ register }} disabled name='name' label='Filnamn' />
          </div>
          <div class='mb-3'>
            <Input controls={{ register }} disabled name='size' label='Storlek' />
          </div>
          <div class='mb-3'>
            <Input controls={{ register }} disabled name='mimetype' label='Mimetype' />
          </div>
          <div class='mb-3'>
            <TextArea rows='4' controls={{ register }} name='notice' label='Beskrivning' error={error} />
          </div>
        </Card>
      </Modal>
    </>
  )
}
