import React from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { ViewHeader, Card, Submit, Input, Select, useSubmit, useGet } from '@enkla-gui'
import { icons } from '@config'
export default function AddonEdit() {
  const { id } = useParams()
  const history = useHistory()
  const { data: addon } = useGet({ endpoint: `api/addon/${id}` })
  const { register, submit, watch, getValues, loading, error } = useSubmit({
    endpoint: 'api/addon',
    method: 'patch',
    form: addon,
    success: () => history.push(`/addon/${id}`)
  })

  return (
    <>
      <ViewHeader
        loading={!watch('id')}
        title={getValues('type')}
        icon={icons.addon}
        subtitle='Redigera'
        return={{ link: `#!/addon/${id}`, title: getValues('name') }}
        buttons={[{ icon: icons.cancel, title: 'Avbryt', href: `#!/addon/${id}`, position: 'right' }]}
      />
      <Card
        loading={!watch('id')}
        buttons={{
          footer: [<Submit onSubmit={submit} text='Spara' sending={loading} />]
        }}>
        <div class='row mb-3'>
          <Input controls={{ register }} name='name' options={{ required: true }} label='Namn' error={error} />
        </div>
        <div class='row mb-3'>
          <Input controls={{ register }} name='fee' options={{ required: true }} label='Belopp' type='number' error={error} />
        </div>
        <div class='row mb-3'>
          <Select
            controls={{ register }}
            name='tax_rate'
            options={{ required: true }}
            label='Momssats(%)'
            error={error}
            values={[
              { key: '0%', value: 0 },
              { key: '6%', value: 6 },
              { key: '12%', value: 12 },
              { key: '25%', value: 25 }
            ]}
          />
        </div>
      </Card>
    </>
  )
}
