import React from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { icons } from '@config'
import { ViewHeader, Card, Submit, Input, useSubmit, useGet } from '@enkla-gui'

export default function CreateAddress() {
  const { id } = useParams()
  const history = useHistory()
  const { register, submit, error, sending } = useSubmit({
    endpoint: 'api/address',
    success: res => {
      history.push(`/tenant/${res.address.tenant_id}`)
    }
  })
  const { data: tenant } = useGet({ endpoint: `api/tenant/${id}` })

  return (
    <>
      <ViewHeader
        loading={!tenant?.id}
        title='Adress'
        icon={icons.address}
        subtitle='Skapa ny faktureringsadress'
        return={{ title: ` ${tenant?.name}`, link: `#!/tenant/${tenant?.id}` }}
      />
      <Card
        loading={!tenant?.id}
        buttons={{
          footer: [<Submit onSubmit={submit} text='Skapa' sending={sending} />]
        }}>
        <input type='hidden' {...register('tenant_id')} value={id} />
        <input type='hidden' {...register('type')} value={'BILLING'} />
        <div class='row mb-3'>
          <Input controls={{ register }} name='address' options={{ required: true }} label='Address' error={error} />
          <Input controls={{ register }} name='postal_code' options={{ required: true }} label='Postnummer' error={error} />
        </div>
        <div class='row'>
          <Input controls={{ register }} name='city' options={{ required: true }} label='Stad' error={error} />
          <Input controls={{ register }} name='country' options={{ required: true }} label='Land' error={error} />
        </div>
      </Card>
    </>
  )
}
