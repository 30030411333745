import React, { useState, useEffect } from 'react'
import { loader } from '@/_helpers'
import { useParams } from 'react-router-dom'
import { API, NoContentBox } from '@enkla-gui'

export default function VerifyEmail() {
  const { token } = useParams()
  const [completed, setCompleted] = useState(false)
  const [error, setError] = useState(false)
  useEffect(() => {
    API.post(`api/emailchange/${token}`)
      .then(res => {
        setCompleted(res)
      })
      .catch(([err, res]) => {
        setError(res)
      })
  }, [token])
  if (!completed && !error) return loader.content()
  return (
    <div style={{ height: '85vh' }} class='bicflex'>
      {completed && <NoContentBox text={completed} icon='fa-check-circle' />}
      {error && <NoContentBox text={error} icon='fa-exclamation-triangle' />}
    </div>
  )
}
