import { BehaviorSubject } from 'rxjs'
import { API } from './API.js'
import { core } from '@config'

const token = new BehaviorSubject(JSON.parse(window.localStorage.getItem('token')))

export const authenticationService = {
  login,
  logout,
  refresh,
  register,
  currentToken: token.asObservable(),
  get currentTokenValue() {
    return token.value
  }
}

function login(email, password) {
  return API.post(core.login, { email, password }).then(res => {
    window.localStorage.setItem('token', JSON.stringify(res))
    token.next(res)
    return res
  })
}
function register(data) {
  return API.post(core.register, data).then(res => {
    return res
  })
}
function refresh() {
  return API.post(core.refresh).then(res => {
    window.localStorage.setItem('token', JSON.stringify(res))
    token.next(res)
    return res
  })
}

//TODO
//support for refresh token request

function logout() {
  window.localStorage.removeItem('token')
  token.next(null)
}
