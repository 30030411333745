import React from 'react'
import { ViewHeader, Card } from '@enkla-gui'
import FAQ from '@components/help/FAQ'
import faq from '@components/help/faq.json'
export default function HelpFAQ() {
	return (
		<>
			<ViewHeader icon='fa-life-ring' title='Hjälp' subtitle='FAQ' />
			<h3 style={{ color: '#3F4254', fontWeight: '700' }}>Vanliga frågor</h3>
			<p class='mb-5' style={{ color: '#7E8299', fontWeight: '600', fontSize: '15px' }}>
				Här svarar vi på de vanligaste frågorna, skulle du inte hitta ett svar kan du alltid{' '}
				<a href='/#!/help/contact' class='text-decoration-none'>
					kontakta oss.
				</a>
			</p>
			<Card>
				{faq.map((f) => (
					<FAQ question={f.q}>
						<p>
							{f.a}
						</p>
						<p></p>
					</FAQ>
				))}
			</Card>
		</>
	)
}
