import React from 'react'
import { ViewHeader, Table, Pagination, Card, useGet, date } from '@enkla-gui'
import { icons } from '@config'
export default function Overview() {
  const { data: tpc } = useGet({ endpoint: 'api/tenantportalconfig' })
  const { data: tenants, setSorting, setPage, sorting } = useGet({ endpoint: `api/tenant` })

  const evalHGPstatus = tenant => {
    if (!tpc?.enabled) return
    if (tenant.password !== null) return <span class='badge bg-success'>Aktivt konto</span>
    if (tenant.invite_sent_at !== null) return <span class='badge bg-primary'>Inbjudan skickad {date.short(tenant.invite_sent_at)}</span>
    return <span class='badge bg-warning'>Ej inbjuden</span>
  }

  return (
    <>
      <ViewHeader
        icon={icons.tenant}
        title='Hyresgäst'
        subtitle='Översikt'
        badges={[
          { hidden: !tpc || !tpc?.enabled, bg: 'success', text: 'Hyresgästportalen aktiv', icon: icons.hgp },
          { hidden: !tpc || tpc?.enabled, bg: 'warning', text: 'Hyresgästportalen inaktiv', icon: icons.hgp }
        ]}
      />
      <Card>
        <Table
          rows={tenants?.data}
          link={`/#!/tenant/@{id}`}
          loadercount={15}
          empty={{
            text: 'Det finns inte några hyresgäster',
            icon: icons.tenant
          }}
          functions={{
            hgpstatus: tenant => evalHGPstatus(tenant)
          }}
          sorting={sorting}
          useSorting={sort => setSorting({ ...sort })}
          keys={['name', 'identifier', 'phone', 'email', 'created_at>date:short', '_this>function:hgpstatus']}
          headers={[
            {
              key: 'name',
              value: 'Namn',
              sortable: true
            },
            {
              key: 'identifier',
              value: 'Identifikation',
              sortable: true
            },
            {
              key: 'phone',
              value: 'Telefonnummer',
              sortable: true
            },
            {
              key: 'email',
              value: 'E-postadress',
              sortable: true
            },
            {
              key: 'created_at',
              value: 'Skapad',
              sortable: true
            },
            {
              value: 'Hyresgästportalen'
            }
          ]}
        />
        <Pagination data={tenants} fetch={setPage} pushToHistory />
      </Card>
    </>
  )
}
