import React from 'react'
import { Explination } from '@enkla-gui'

export default function Select({
  controls,
  name,
  options,
  label,
  error,
  values,
  onChange = false,
  disabled = false,
  noInitialValue = false,
  clearable = false,
  cols = 'col',
  tip = false,
  placeholder = 'Välj ett alternativ...'
}) {
  const { register, setValue, getValues } = controls
  var message
  const hasError = () => {
    var data = error.data
    if (data === undefined) return false
    name.split('.').forEach(value => {
      if (value in data) {
        data = data[value]
      } else {
        data = []
      }
    })
    if (data.length === 0) return false
    message = data[0]
    return true
  }
  return (
    <div class={`component_Select ${cols}`}>
      <div class={`form-group ${disabled ? 'form-group-disabled' : ''}`}>
        <label>
          {label}
          {options?.required && <span class='input-required'> * </span>}
          {tip && <Explination tip={tip} />}
        </label>
        {!disabled && clearable && (
          <span
            onClick={() => {
              if (onChange) {
                onChange(null)
              }
              setValue(name, null)
            }}
            class='select-clear'>
            <i class='fas fa-times' />
          </span>
        )}
        <select onInput={e => onChange && onChange(e.target.value)} {...register(name, options)} type='select' class='form-select' disabled={disabled}>
          <option disabled={noInitialValue} selected={clearable && getValues && values.filter(value => value.value === getValues(name)).length === 0} value=''>
            {placeholder}
          </option>
          {values.map(
            value =>
              !value.hidden && (
                <option selected={value.selected} value={value.value}>
                  {value.key}
                </option>
              )
          )}
        </select>
        {error && hasError() && (
          <div class='form-inline-error'>
            <i class='fa fa-fw fa-exclamation-triangle' />
            {message}
          </div>
        )}
      </div>
    </div>
  )
}
