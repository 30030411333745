import React from 'react'
import { ViewHeader, Card, Pagination, Table, useGet } from '@enkla-gui'
import { icons } from '@config'
export default function MaintenanceTemplateOverview() {
  const { data: templates, setSorting, sorting, setPage } = useGet({ endpoint: `api/maintenance/template`, filter: { limit: 15 } })
  return (
    <>
      <ViewHeader
        title='Rutiner'
        subtitle='Översikt'
        icon={icons.maintenance}
        buttons={[{ icon: icons.create, title: 'Ny rutin', href: '#!/maintenance/template/create' }]}
      />
      <Card>
        <Table
          rows={templates?.data}
          link={`/#!/maintenance/template/@{id}`}
          sorting={sorting}
          empty={{
            text: 'Det finns inga rutiner',
            icon: icons.maintenance
          }}
          keys={['title', 'activities_count', 'updated_at>date:short', 'created_at>date:short']}
          useSorting={sort => setSorting({ ...sort })}
          headers={[
            {
              value: 'Titel',
              sortable: true,
              key: 'title'
            },
            { value: 'Antal aktiviteter', key: 'activities_count' },
            {
              key: 'udpated_at',
              value: 'Uppdaterad',
              sortable: true,
              class: 'min'
            },
            {
              key: 'created_at',
              value: 'Skapad',
              sortable: true,
              class: 'min'
            }
          ]}
        />
        <Pagination data={templates} fetch={setPage} />
      </Card>
    </>
  )
}
