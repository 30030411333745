import React from 'react'
import { ViewHeader, Card, Pagination, Table, useGet } from '@enkla-gui'
import { icons } from '@config'
export default function AddonOverview() {
  const { data: addons, setSorting, sorting, setPage } = useGet({ endpoint: `api/addon` })
  return (
    <>
      <ViewHeader title='Tillägg & Avdrag' subtitle='Översikt' icon={icons.addon} buttons={[{ icon: icons.create, title: 'Skapa', href: '#!/addon/create' }]} />
      <Card>
        <Table
          rows={addons?.data}
          link={`/#!/addon/@{id}`}
          sorting={sorting}
          empty={{
            text: 'Du har inte skapat något tillägg eller avdrag',
            icon: icons.addon
          }}
          useSorting={sort => setSorting({ ...sort })}
          functions={{
            type: addon => (addon.fee >= 0 ? <span class='badge bg-success w-100'>Tillägg</span> : <span class='badge bg-danger w-100'>Avdrag</span>)
          }}
          keys={['_this>function:type', 'name', 'fee>currency', 'tax_rate>%', 'created_at>date:short']}
          headers={[
            {
              value: 'Typ',
              class: 'min'
            },
            {
              value: 'Namn',
              sortable: true,
              key: 'name'
            },
            {
              value: 'Belopp',
              sortable: true,
              key: 'fee'
            },
            {
              value: 'Momssats',
              key: 'tax_rate',
              sortable: true
            },
            {
              key: 'created_at',
              value: 'Skapad',
              sortable: true,
              class: 'min'
            }
          ]}
        />
        <Pagination data={addons} fetch={setPage} />
      </Card>
    </>
  )
}
