import React from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { ViewHeader, Card, Submit, Input, useGet, useSubmit } from '@enkla-gui'
import { icons } from '@config'

export default function CompanyEdit() {
  const { id } = useParams()
  const history = useHistory()

  const { data: company } = useGet({ endpoint: `api/company/${id}` })
  const { register, submit, error, loading, getValues } = useSubmit({
    endpoint: 'api/company',
    form: company,
    method: 'patch',
    success: () => history.push(`/company/${id}`)
  })

  return (
    <>
      <ViewHeader
        loading={!company}
        title='Företag'
        icon={icons.company}
        subtitle='Redigera'
        return={{ link: `#!/company/${id}`, title: getValues('name') }}
        buttons={[{ icon: icons.cancel, title: 'Avbryt', href: `#!/company/${id}`, position: 'right' }]}
      />
      <Card
        loading={!company}
        buttons={{
          footer: [<Submit onSubmit={submit} text='Spara' sending={loading} />]
        }}>
        <input type='hidden' {...register('tenant_id')} value={id} />
        <div class='row mb-3'>
          <Input controls={{ register }} name='name' options={{ required: true }} label='Namn' error={error} />
          <Input controls={{ register }} name='organisation_number' options={{ required: true }} label='Organisationsnummer' error={error} />
        </div>
        <div class='row mb-3 guide-step-2'>
          <Input controls={{ register }} name='address' options={{ required: true }} label='Adress' error={error} />
          <Input controls={{ register }} name='postal_code' options={{ required: true }} label='Postnummer' error={error} />
        </div>
        <div class='row mb-3'>
          <Input controls={{ register }} name='city' options={{ required: true }} label='Stad' error={error} />
          <Input controls={{ register }} name='country' options={{ required: true }} label='Land' error={error} />
        </div>
        <div class='row'>
          <Input controls={{ register }} name='phone' options={{ required: false }} label='Telefonnummer' error={error} />
          <Input controls={{ register }} name='email' options={{ required: false }} label='Epost-adress' error={error} />
        </div>
      </Card>
    </>
  )
}
