import React, { useEffect } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { sidebarCollapsedObserver } from '@enkla-gui'

export default function CollapsableItem({ setActive, keyValue, link, title, badge = false }) {
  const active = useRouteMatch(link.replace('#!', ''))

  useEffect(() => {
    setActive(active)
  }, [active, setActive])

  return (
    <li class='sidebar-subitem' key={keyValue}>
      <a onClick={() => window.innerWidth < 450 && sidebarCollapsedObserver.next(false)} class={`sidebar-link rounded ${active ? 'active' : ''}`} href={link}>
        <div>
          <i class='far fa-fw fa-circle' />
          {title}
        </div>
        {badge && badge}{' '}
      </a>
    </li>
  )
}
