import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { notify, date as dateHelper } from '@/_helpers'
import { API, ViewHeader, Card, Submit, Input, Select, Output, Date, SearchModal, CheckBox, Help } from '@enkla-gui'

export default function Create() {
  const { register, handleSubmit, control, reset, watch, getValues, setValue } = useForm()
  const [error, setError] = useState(null)
  const [sending, setSending] = useState(false)
  const [entity, setEntity] = useState(false)
  const [searchModal, setSearchModal] = useState(false)
  const [tenant, setTenant] = useState(false)
  const { id } = useParams()
  const history = useHistory()

  useEffect(() => {
    if (!entity) return
    setValue('lease.tax_rate', getValues('tenant.organisation') === 'true' ? entity.type.organisation_tax_rate : entity.type.private_tax_rate)
  }, [watch('tenant.organisation')])

  useEffect(() => {
    API.get(`api/entity/${id}`).then(entity => {
      reset({
        lease: {
          fee: entity.fee,
          fee_as_m2: entity.fee_as_m2,
          notice_type: 'MONTH',
          payment_interval: 'MONTHLY',
          tax_rate: 0
        },
        address: {
          address: entity.address,
          postal_code: entity.postal_code,
          city: entity.city,
          country: entity.country
        },
        tenant: {
          organisation: false
        }
      })
      setEntity(entity)
    })
  }, [id])

  const create = data => {
    setSending(true)
    if (!data.lease.start || data.lease.start === undefined) data.lease.start = dateHelper.today()
    //select returnerar ett textvärde LOL?????
    data.tenant.organisation = data.tenant.organisation === 'true'
    API.post('api/lease', data)
      .then(async res => {
        notify.toast(`Uthyrning skapad.`)
        history.push(`/lease/${res.lease.id}`)
      })
      .catch(([err, res]) => {
        notify.toast(res.message, 'error')
        setSending(false)
        setError({
          message: res.message,
          data: res.data
        })
      })
  }

  const connect = data => {
    setSending(true)
    if (!data.lease.start || data.lease.start === undefined) data.lease.start = dateHelper.today()
    data.tenant.id = tenant.id
    API.post('api/lease/connect', data)
      .then(async res => {
        notify.toast(`Uthyrning skapad.`)
        history.push(`/lease/${res.lease.id}`)
        promptUpdateAddress()
      })
      .catch(([err, res]) => {
        notify.toast(res, 'error')
        setError({
          message: res,
          data: res.data
        })
        setSending(false)
      })
  }

  const promptUpdateAddress = async () => {
    if (entity.type.id === '0f860f6d-8aca-4bdb-815c-8eb42490d689') {
      await notify.request(
        `Du har hyrt ut en lägenhet till en befintlig hyresgäst, vill du uppdatera hyresgästens primära adress till lägenhets adress?`,
        async () => {
          API.patch('api/address', {
            ...tenant.address,
            address: entity.address,
            postal_code: entity.postal_code,
            city: entity.city,
            country: entity.country
          })
            .then(res => {
              notify.toast(`Adressen för ${tenant.name} har uppdaterats`)
            })
            .catch(([err, res]) => {
              notify.toast(`Något gick fel: ${res.message}`, 'error')
            })
        },
        'Uppdatera adressuppgifter'
      )
    }
  }

  const handleResponse = response => {
    API.get(`api/tenant/${response.render.id}`)
      .then(tenant => {
        setTenant(tenant)
      })
      .catch(([err, res]) => {
        notify.toast(res.message, 'error')
      })
    setSearchModal(false)
  }

  return (
    <>
      <ViewHeader
        loading={!entity}
        title={`Hyr ut ${entity?.type?.name}`}
        icon='fa-key'
        subtitle={entity.name}
        return={{ link: `#!/entity/${entity.id}`, title: entity.name }}
      />
      <Card
        loading={!entity}
        title='Hyresgäst'
        icon='fa fa-fw fa-address-book'
        buttons={{
          header: [
            {
              hidden: tenant,
              callback: () => setSearchModal(true),
              icon: 'fas fa-search text-light',
              text: 'Sök befintlig hyresgäst'
            },
            {
              hidden: !tenant,
              callback: () => setTenant(false),
              icon: 'fas fa-user-plus text-light',
              text: 'Skapa ny hyresgäst'
            }
          ]
        }}>
        <input type='hidden' {...register('lease.entity_id')} value={id} />
        {!tenant && (
          <>
            <div class='row mb-3'>
              <Select
                controls={{ register }}
                name='tenant.organisation'
                label='Kundtyp'
                error={error}
                values={[
                  { key: 'Privatkund', value: false },
                  { key: 'Företagskund', value: true }
                ]}
              />
              <Input controls={{ register }} name='tenant.name' options={{ required: true }} label='Namn' error={error} />
            </div>
            <div class='row mb-3'>
              <Input controls={{ register }} name='tenant.identifier' options={{ required: true }} label='Personnummer / Organisationsnummer' error={error} />
              <Input controls={{ register }} name='tenant.phone' options={{ required: true }} label='Telefonnummer' error={error} />
            </div>
            <div class='row mb-3'>
              <Input controls={{ register }} name='tenant.email' options={{ required: true }} label='E-post (kontakt)' error={error} />
              <Input
                controls={{ register }}
                name='tenant.billing_email'
                options={{ required: false }}
                label='E-post (Fakturering)'
                placeholder='Kontakt-e-post används för fakturor om inget annat anges.'
                error={error}
              />
            </div>
            <div class='row mb-3'>
              <Input controls={{ register }} name='address.address' options={{ required: true }} label='Adress' error={error} />
              <Input controls={{ register }} name='address.postal_code' options={{ required: true }} label='Postnummer' error={error} />
            </div>
            <div class='row'>
              <Input controls={{ register }} name='address.city' options={{ required: true }} label='Stad' error={error} />
              <Input controls={{ register }} name='address.country' options={{ required: true }} label='Land' error={error} />
            </div>
          </>
        )}
        {tenant && (
          <>
            <div class='row mb-3'>
              <Output value={tenant.name} label='Namn' />
              <Output value={tenant.identifier} label='Personnummer / Organisationsnummer' />
            </div>
            <div class='row mb-3'>
              <Output value={tenant.phone} label='Telefonnummer' />
            </div>
            <div class='row mb-3'>
              <Output value={tenant.email} label='E-post' />
              <Output value={tenant.billing_email} label='E-post (Fakturering)' />
            </div>
            <div class='row mb-3'>
              <Output value={tenant.address.address} label='Address' />
              <Output value={tenant.address.postal_code} label='Postnummer' />
            </div>
            <div class='row'>
              <Output value={tenant.address.city} label='Stad' />
              <Output value={tenant.address.country} label='Land' />
            </div>
          </>
        )}
      </Card>
      <Card
        loading={!entity}
        title='Uthyrning'
        icon='fa fa-fw fa-key'
        buttons={{
          footer: [<Submit onSubmit={handleSubmit(tenant ? connect : create)} text='Skapa' sending={sending} />]
        }}>
        <div class='row mb-3'>
          <Input controls={{ register }} name='lease.reference' options={{ required: false }} label='Avtalsreferens' error={error} />
          <Select
            controls={{ register }}
            name='lease.tax_rate'
            options={{ required: true }}
            label='Momssats(%)'
            error={error}
            values={[
              { key: '0%', value: 0 },
              { key: '6%', value: 6 },
              { key: '12%', value: 12 },
              { key: '25%', value: 25 }
            ]}
          />
        </div>
        <div class='row mb-3'>
          <div class='col'>
            <Input controls={{ register }} name='lease.fee' options={{ required: true }} label='Avgift' type='number' error={error} />
          </div>

          <div class='col' hidden={!entity.fields?.area}>
            <div class='form-group'>
              <label></label>
              <br />
              <CheckBox controls={{ register, getValues, setValue }} name={`lease.fee_as_m2`} options={{ required: false }} label={'Pris per m2'} />
            </div>
          </div>

          <div class='mt-3' hidden={!watch('lease.fee_as_m2')}>
            <Help temporary header={false} type='info'>
              <p>
                Objektet är <b>{entity.fields?.area || 0}m2</b>
              </p>
              <p>
                Avgiften blir <b>{watch('lease.fee') * entity.fields?.area} SEK</b>
              </p>
            </Help>
          </div>
        </div>
        <div class='row mb-3'>
          <Select
            controls={{ register }}
            name='lease.payment_interval'
            options={{ required: true }}
            label='Betalningsintervall'
            tip='Angivet intervall hanteras som kalenderperiod'
            error={error}
            values={[
              { key: 'Månadsvis', value: 'MONTHLY' },
              { key: 'Kvartalsvis', value: 'QUARTERLY' },
              { key: 'Årligen', value: 'YEARLY' }
            ]}
          />
        </div>
        <div class='row mb-3'>
          <Input controls={{ register }} name='lease.notice_length' options={{ required: true }} label='Uppsägningstid' error={error} />
          <Select
            controls={{ register }}
            name='lease.notice_type'
            options={{ required: true }}
            label='Enhet'
            error={error}
            values={[
              { key: 'Dag(ar)', value: 'DAY' },
              { key: 'Månad(er)', value: 'MONTH' },
              { key: 'År', value: 'YEAR' }
            ]}
          />
        </div>
        <div class='row mb-3'>
          <Date label='Tillträde' controls={{ control }} name='lease.start' error={error} placeholder='Välj ett datum, vid tomt värde anges dagens datum' />
          <Date label='Slutdatum' controls={{ control }} name='lease.stop' error={error} />
        </div>
      </Card>
      <SearchModal
        search={async query =>
          await API.get('api/search', [
            ['query', query],
            ['index', 'tenants']
          ])
        }
        open={searchModal}
        close={() => setSearchModal(false)}
        callback={result => handleResponse(result)}
      />
    </>
  )
}
