import React from 'react'
import moment from 'moment'
import { date, cashmoneyhelper, NoContentBox, TableLoader } from '@enkla-gui'

export default function Table(props) {
  let rowKeys = props.keys

  if (Array.isArray(props.keys)) {
    rowKeys = props.keys.join('|')
  }
  const getValueFromPath = function (obj, path) {
    var stack = path.split('.')
    while (stack.length) {
      if (!obj) return null
      obj = obj[stack.shift()]
    }
    return obj
  }
  const onRowClick = row => {
    if (props.link) {
      window.location.href = getLinkHref(row, props.link)
    }
    if (props.onClick) {
      props.onClick(row)
    }
  }
  const getLinkHref = (row, link) => {
    return link.replace(/@{([^}]+)}/gi, (match, property) => getValueFromPath(row, property))
  }
  const parseKey = (value, key, row, x, y) => {
    let col = ''
    switch (key[0]) {
      case 'link':
        col = (
          <a key={`table_row_${x}_${y}_key_${key}`} href={getLinkHref(row, key[1])} onClick={e => e.stopPropagation()} class='btn btn-sm btn-light'>
            <i class={`fas fa-fw ${key[2]}`} />
          </a>
        )
        break
      case 'date':
        if (value === null || value === undefined) {
          col = ''
        } else if (typeof date[key[1]] === 'function') {
          col = date[key[1]](value)
        } else {
          col = moment.unix(value).locale('sv').fromNow()
        }
        break
      case 'label':
        col = (
          <span key={`table_row_${x}_${y}_key_${key}`} class={`badge bg-${key[1]} ${key[2] ? key[2] : ''}`}>
            {value}
          </span>
        )
        break
      case 'button':
        col = (
          <button
            key={`table_row_${x}_${y}_key_${key}`}
            onClick={e => {
              e.stopPropagation()
              props.functions[key[1]](row)
            }}
            class='btn btn-sm btn-light'>
            <i class={`fas fa-fw ${key[2]}`} />
          </button>
        )
        break
      case 'buttons':
        var buttons = []
        for (let index = 1; index < key.length; index = index + 2) {
          buttons.push(
            <button
              key={`table_row_${x + index}_${y + index}_key_${key}`}
              onClick={e => {
                e.stopPropagation()
                props.functions[key[index]](row)
              }}
              class={`btn btn-sm btn-light ${key.length !== index + 2 ? 'mr-10' : ''}`}>
              <i class={`fas fa-fw ${key[index + 1]}`} />
            </button>
          )
        }
        col = buttons
        break
      case 'function':
        if (props.functions.hasOwnProperty(key[1])) {
          col = props.functions[key[1]](value)
        }
        break
      case 'currency':
        if (value === null) value = 0
        col = cashmoneyhelper.format(parseInt(value))
        break
      default:
        col = `${value}${key[0]}`
    }

    return col
  }

  const keyLoop = function (row, key, x, y) {
    key = key.split('>')
    let col = key[0] === '_this' ? row : getValueFromPath(row, key[0])
    if (key.length > 1) {
      let operators = key[1].split('+')
      operators.forEach(operator => (col = parseKey(col, operator.split(':'), row, x, y)))
      if (operators.includes('nullable') && getValueFromPath(row, key[0]) === null) col = ''
    }
    return col
  }
  const sort = header => {
    if (!header.sortable) return
    props.useSorting({
      'sort[by]': header.key,
      'sort[order]': props.sorting['sort[order]'] === 'asc' ? 'desc' : 'asc'
    })
  }
  if (props?.rows?.length === 0) return <NoContentBox text={props.empty?.text || 'Det finns ingen data'} icon={props.empty?.icon || 'fa-info-circle'} />
  if (props.fetching || props.rows === undefined) return <TableLoader rows={props.loadercount || 3} />
  return (
    <div class='component_Table table-responsive'>
      <table class={`table align-middle table-nowrap mb-0`}>
        <thead>
          <tr>
            {props.headers &&
              props.headers.map((header, x) => {
                if (header.hidden) return
                return (
                  <th
                    key={`table_head_${x}`}
                    onClick={() => sort(header)}
                    class={(header.sortable ? 'sortable-table-head ' : '') + (header.class ? header.class : '')}>
                    <span>
                      {header.value}
                      {props.sorting['sort[by]'] === undefined && header.key === 'created_at' ? (
                        <i class='fas fa-sort-alpha-up-alt sort-icon' />
                      ) : header.sortable && props.sorting['sort[by]'] === header.key ? (
                        props.sorting['sort[order]'] === 'asc' ? (
                          <i class='fas fa-sort-alpha-down sort-icon' />
                        ) : (
                          <i class='fas fa-sort-alpha-up-alt sort-icon' />
                        )
                      ) : (
                        ''
                      )}
                    </span>
                  </th>
                )
              })}
            {props.children}
          </tr>
        </thead>
        <tbody>
          {props.rows &&
            props.rows.map((row, x) => {
              if (rowKeys) {
                var keys = rowKeys.split('|')
                return (
                  <tr key={`table_row_${x}`} onClick={e => onRowClick(row)} class={`${props.link ? 'pointer' : ''}`}>
                    {keys.map((key, y) => {
                      if (props.headers && props.headers[y].hidden) return
                      key = key.split('&')
                      return <td key={`table_row_${x}_col_${y}`}>{key.map(key => keyLoop(row, key, x, y))}</td>
                    })}
                  </tr>
                )
              }
              return (
                <tr key={`table_row_${x}`}>
                  {Object.values(row).map((value, y) => (
                    <td key={`table_row_${x}_col_${y}`}>{value}</td>
                  ))}
                </tr>
              )
            })}
        </tbody>
      </table>
    </div>
  )
}
