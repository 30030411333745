import { icons } from '../_config/icons'
import { v4 as uuidv4 } from 'uuid'

export const maintenancehelper = {
  statusBadge(status) {
    switch (status) {
      case 'PLANNED':
        return (
          <span key={uuidv4()} class={`badge bg-light`}>
            Planerad
          </span>
        )
      case 'IN_PROGRESS':
        return (
          <span key={uuidv4()} class={`badge bg-warning`}>
            Pågår
          </span>
        )
      case 'DONE':
        return (
          <span key={uuidv4()} class={`badge bg-success`}>
            Klar
          </span>
        )
      case 'OVERDUE':
        return (
          <span key={uuidv4()} class={`badge bg-danger`}>
            Förfallen
          </span>
        )
      case 'CANCELLED':
        return (
          <span key={uuidv4()} class={`badge bg-warning`}>
            Makulerad
          </span>
        )
      default:
        return (
          <span key={uuidv4()} class={`badge bg-warning`}>
            Okänd status
          </span>
        )
    }
  }
}
