import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { API } from '@enkla-gui'

export default function Forgot() {
  const { register, handleSubmit } = useForm()
  const [error, setError] = useState(null)
  const [sending, setSending] = useState(false)
  const [success, setSuccess] = useState(false)

  var onSubmit = data => {
    setSending(true)
    setError(null)
    API.post('api/auth/forgot', data)
      .then(res => {
        setSuccess('Ett mail har skickats med återställningsinstruktioner')
        setSending(false)
      })
      .catch(([error, res]) => {
        setError({
          message: res.message,
          data: res.data
        })
        setSending(false)
      })
  }
  return (
    <div class='full-height login-wrapper'>
      <div class='col bicflex'>
        <div class='user-form'>
          <form onSubmit={handleSubmit(onSubmit)} class='user'>
            <div class='row'>
              <div class='col-md p-5 pb-0'>
                <div class='row'></div>
                <div class='row mb-3'>
                  <div className='col'>
                    {' '}
                    <img width='250' src={`${process.env.PUBLIC_URL}/images/logo-black.svg`} />
                    <h4 class='text'>
                      Välkommen till Enkla Fastigheter! <span class=' wave'>👋</span>
                    </h4>
                    <p class='text-divider'>
                      <span>Återställ lösenord</span>
                    </p>
                  </div>
                </div>
                {success && <div class='alert alert-success'>{success}</div>}
                {error && <div class='alert alert-danger'>{error.message}</div>}
                <div class='mb-3'>
                  <label>E-post</label>
                  <input {...register('email', { required: true })} type='text' class='form-control form-control-register' />
                </div>
                <div class='col-md mb-3'>
                  <button type='submit' disabled={sending} class='btn register-button'>
                    {sending ? 'Skickar...' : 'Återställ Lösenord'}
                  </button>
                </div>
                <div class='mb-5'>
                  <span class='small text pull-right'>
                    <a class='link' href='#/login'>
                      Tillbaka till inloggning
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class='col d-none d-md-block bicflex login-hero divider'>
        <div class='login-hero-content bicflex'>
          <img style={{ width: '75%', display: 'block' }} src={`${process.env.PUBLIC_URL}/images/forgot_password.svg`} />
        </div>
      </div>
    </div>
  )
}
