import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { ViewHeader, Card, Submit, Input, Help, useSubmit, useGet, TextArea } from '@enkla-gui'
import { icons } from '@config'
import MaintenanceTasks from '@components/maintenance/MaintenanceTasks'
import { BehaviorSubject } from 'rxjs'

export default function MaintenanceTemplateCreate() {
  const history = useHistory()
  const [tasks, setTasks] = useState(new BehaviorSubject([]))

  const { register, error, loading, submit, setValue } = useSubmit({
    endpoint: 'api/maintenance/template',
    success: res => history.push(`/maintenance/template/${res.template.id}`)
  })

  return (
    <>
      <ViewHeader title='Rutiner' icon={icons.maintenance} subtitle='Skapa' return={{ link: '#!/maintenance/template/overview', title: 'Översikt' }} />
      <div class='row'>
        <Card>
          <div class='row mb-3'>
            <div class='col'>
              <Input controls={{ register }} name='title' options={{ required: true }} label='Namn' error={error} />
            </div>
            <div class='col'>
              <Input
                controls={{ register }}
                type='number'
                name='reschedule_in'
                options={{ required: false }}
                label='Återkommande efter antal dagar'
                error={error}
              />
            </div>
          </div>
          <div class='row'>
            <TextArea controls={{ register }} name='description' options={{ required: true }} label='Beskrivning' error={error} />{' '}
          </div>
        </Card>
      </div>
      <div class='row'>
        <Card>
          <MaintenanceTasks tasks={tasks} />
        </Card>
      </div>
      <div class='d-grid gap-2'>
        <Submit
          onSubmit={() => {
            setValue('tasks', tasks.value)
            submit()
          }}
          text='Skapa rutin'
          sending={loading}
        />
      </div>
    </>
  )
}
