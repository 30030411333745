import React, { useState, useEffect } from 'react'
import { BeefyButton } from '@enkla-gui'

export default function CheckBox({ controls, name, options, label }) {
  const { setValue, getValues, register } = controls

  const [isChecked, setIsChecked] = useState(getValues(name))
  useEffect(() => {
    setValue(name, isChecked)
  }, [isChecked, name, setValue])
  return (
    <div class='component_CheckBox'>
      <input {...register(name, options)} type='hidden' value={isChecked} />
      <BeefyButton
        _default={{
          text: label,
          icon: isChecked ? 'fa fa-check' : 'fa fa-times'
        }}
        hover={{
          text: label,
          icon: !isChecked ? 'fa fa-check' : 'fa fa-times'
        }}
        type={!isChecked ? 'info' : 'success'}
        callback={() => setIsChecked(!isChecked)}
      />
    </div>
  )
}
