import React, { useContext, useEffect, useState } from 'react'
import { NoContentBox } from '@enkla-gui'
import faq from './faq.json'
import './Pipey.scss'
import { PipeySearchContext } from '@providers/PipeyProvider.js';
import Overlay from './Overlay';
export default function Pipey() {
	const { search, setSearch } = useContext(PipeySearchContext)
	const [results, setResults] = useState(faq);
	const [active, setActive] = useState(false)

	useEffect(() => {
		if (search.trim().length === 0) return setResults(faq)
		dispatch(search)
		if (!active) setActive(true)
	}, [search]);


	const dispatch = value => {
		const searchWords = value.toLowerCase().split(/\s+/)
		setResults(faq.filter(d =>
			searchWords.every(word => d.a.toLowerCase().includes(word) || d.q.toLowerCase().includes(word))
		));
	}

	const highlightText = (text) => {
		if (search === "") return <div dangerouslySetInnerHTML={{ __html: text }} />

		const regex = /(<[^>]+>|[^<]+)/g
		const parts = text.match(regex)

		const searchWords = search.split(/\s+/)
		const searchPattern = searchWords.map(word => `(${word})`).join('|')
		const regexSearch = new RegExp(searchPattern, 'gi')

		const highlightedParts = parts.map((part, index) => {
			if (part.startsWith('<')) {
				return part;
			} else {
				return part.replace(regexSearch, '<mark key=' + index + '>$&</mark>');
			}
		});
		const combined = highlightedParts.join('');
		return <div dangerouslySetInnerHTML={{ __html: combined }} />;
	};
	return (
		<>
			{active && <Overlay onClick={() => setActive(false)} />} {/* Overlay with click handler */}
			<li style={{ 'zIndex': '200000' }} class={`nav-item dropdown ${active ? 'active' : ''} `}>
				<button class='nav-link' onClick={e => setActive(!active)}>
					<span class='fa-stack'>
						<i class='fa fa-question-circle fa-stack-1x' />
					</span>
				</button>
				<ul class={`card pipey-dropdown user-dropdown dropdown-menu ${active ? 'show' : ''} `}>
					<div class="component_Pipey">
						<h3>Frågor och svar</h3>
						<div class='form-group'>
							<input value={search} type='text' class='form-control' placeholder='Sök...' onChange={e => setSearch(e.target.value)} />
						</div>
						<hr />
						<div class="qas">
							{results.map(f => (
								<div class="qa-wrapper">
									<span>{highlightText(f.q)}</span>
									<span>{highlightText(f.a)}</span>
								</div>
							))}
							{results.length === 0 && (
								<NoContentBox text='Din sökning gav inga träffar' />
							)}
						</div>
					</div>
				</ul>
			</li >
		</>
	)
}
