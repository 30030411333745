import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { ViewHeader, Card, Submit, Input, useGet, useSubmit, TextArea, Date } from '@enkla-gui'
import { icons } from '@config'
import MaintenanceTasks from '@components/maintenance/MaintenanceTasks'
import { BehaviorSubject } from 'rxjs'

export default function MaintenanceEditView() {
  const { id } = useParams()
  const history = useHistory()

  const { data: activity } = useGet({ endpoint: `api/maintenance/${id}` })
  const [tasks, setTasks] = useState(false)
  useEffect(() => {
    if (activity) {
      const parsedTasks = new BehaviorSubject(activity.tasks)
      setTasks(parsedTasks)
    }
  }, [activity])
  const { register, submit, error, loading, getValues, setValue, control } = useSubmit({
    endpoint: 'api/maintenance',
    form: activity,
    method: 'patch',
    success: () => history.push(`/maintenance/${id}`)
  })

  return (
    <>
      <ViewHeader
        loading={!activity}
        title='Förvaltningsaktivitet'
        icon={icons.maintenance}
        subtitle='Redigera'
        return={{ link: `#!/maintenance/${id}`, title: getValues('title') }}
        buttons={[{ icon: icons.cancel, title: 'Avbryt', href: `#!/maintenance/${id}`, position: 'right' }]}
      />
      <div class='row'>
        <Card loading={!activity}>
          <div class='row mb-3'>
            <div class='col'>
              <Input controls={{ register }} name='title' options={{ required: true }} label='Namn' error={error} />
              <TextArea controls={{ register }} name='description' options={{ required: true }} label='Beskrivning' error={error} />{' '}
            </div>
          </div>
          <div class='row mb-3'>
            <div class='col'>
              <Date label='Schemalagt datum' controls={{ control }} name='scheduled_at' error={error} />
            </div>
            <div class='col'>
              <Input
                controls={{ register }}
                type='number'
                name='reschedule_in'
                options={{ required: false }}
                label='Återkommande efter antal dagar'
                error={error}
              />
            </div>
          </div>
        </Card>
      </div>
      <div class='row'>
        <Card loading={!activity}>{tasks && <MaintenanceTasks tasks={tasks} />}</Card>
      </div>
      <div class='d-grid gap-2'>
        <Submit
          onSubmit={() => {
            setValue('tasks', tasks.getValue())
            submit()
          }}
          text='Uppdatera aktivitet'
          sending={loading}
        />
      </div>
    </>
  )
}
