import React, { useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'

export default function Pagination({ data, fetch, pushToHistory }) {
  const location = useLocation()
  const { page } = useParams()
  const getLink = pageNumber => {
    if (pushToHistory) {
      if (page) {
        return `#!${location.pathname.split('/').slice(0, -1).join('/')}/${pageNumber}`
      } else {
        return `#!${location.pathname}/${pageNumber}`
      }
    }
    return null
  }
  const onClick = pageNumber => {
    if (pushToHistory) return
    return fetch(pageNumber)
  }
  const getLinkLabel = label => {
    switch (label) {
      case 'pagination.previous':
        return <i class='fa fa-chevron-left' />
      case 'pagination.next':
        return <i class='fa fa-chevron-right' />
      default:
        return label
    }
  }
  const printLinks = () => {
    var output = []
    for (let { url, label, active } of data?.links || []) {
      let page = url && new URLSearchParams(new URL(url).search).get('page')
      output.push(
        <li key={url + label} class={'page-item ' + (active ? 'active ' : '') + (page ? '' : 'disabled')}>
          <a class='page-link' onClick={e => onClick(page)} href={getLink(page)}>
            {getLinkLabel(label)}
          </a>
        </li>
      )
    }
    return output
  }
  useEffect(() => {
    if (page) fetch(page)
  }, [page, fetch])

  return (
    <>
      {data && data.last_page !== 1 && (
        <div class='component_Pagination d-flex justify-content-center'>
          <nav>
            <ul class='pagination'>{printLinks()}</ul>
          </nav>
        </div>
      )}
    </>
  )
}
