import { useCallback, useEffect, useState } from 'react'

import { API } from '@enkla-gui'

export const useGet = ({ endpoint, params = null, filter: _filter = {} }) => {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(null)
  const [error, setError] = useState(null)
  const [sorting, setSorting] = useState({})
  const [filter, setFilter] = useState(_filter)
  const [page, setPage] = useState(0)

  const get = useCallback(() => {
    setLoading(true)
    API.get(endpoint, { ...params, ...filter, ...sorting, page: page })
      .then(res => {
        setData(res)
        setLoading(false)
      })
      .catch(err => {
        setError(err)
        setLoading(false)
      })
  }, [endpoint, params, sorting, filter, page])

  useEffect(() => {
    get()
  }, [get])

  return { data, loading, error, fetch: get, setPage, setSorting, setFilter, sorting }
}
