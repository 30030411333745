import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useParams, useHistory } from 'react-router-dom'
import { notify, date, invoicebasehelper } from '@/_helpers'
import { API, Card, Table, Pagination, Submit, Date } from '@enkla-gui'
import Modal from 'react-modal'

export default function Invoicebases(props) {
  const { register, handleSubmit, control } = useForm()
  const [company, setCompany] = useState(null)
  const [error, setError] = useState(null)
  const [invoicebases, setInvoicebases] = useState([])
  const [sorting, setSorting] = useState({})
  const [modal, setModal] = useState(false)
  let { page } = useParams()
  const history = useHistory()

  useEffect(() => {
    if (!props.lease || props.lease === undefined) return
    fetchInvoicebases()

    API.get(`api/company/${props.lease.entity.company_id}`).then(company => setCompany(company))
  }, [props.lease, sorting])

  const fetchInvoicebases = async (p = page || 1) => {
    API.get(`api/invoicebase/lease/${props.lease.id}`, {
      page: p,
      ...sorting
    }).then(invoicebases => {
      setInvoicebases(invoicebases)
    })
  }
  const create = data => {
    API.post('api/invoicebase', data)
      .then(res => {
        fetchInvoicebases()
        setModal(false)
        if (company.invoicingconfig && company.invoicingconfig.generate_day !== null) {
          notify.alert(
            'Underlaget har skapats',
            'Denna uthyrning har automatisk hyresavisering. När du manuellt skapar upp ett underlag bör du därför se till att datum för nästa fakturamånad stämmer.'
          )
        } else {
          notify.toast('Underlaget har skapats')
        }
        history.push(`/invoicebase/${res.invoicebase.id}`)
      })
      .catch(([err, res]) => {
        setError({
          message: res.message,
          data: res.data
        })
      })
  }
  return (
    <>
      <Card
        title='Hyresavier'
        icon='fas fa-file-invoice'
        buttons={{
          header: [
            <button class='btn btn-primary btn-sm text-light' onClick={() => setModal(true)}>
              <i class='fas fa-plus-square text-light' /> Skapa
            </button>
          ]
        }}>
        <Table
          rows={invoicebases.data}
          empty={{ text: 'Det finns inte några hyresavier', icon: 'fas fa-file-invoice' }}
          sorting={sorting}
          useSorting={sort => setSorting({ ...sort })}
          link={`/#!/invoicebase/@{id}`}
          functions={{
            status: status => <span class={`badge w-100 bg-${invoicebasehelper.status(status).color}`}>{invoicebasehelper.status(status).label}</span>,
            period: invoice => date.short(invoice.start) + ' - ' + date.short(invoice.stop),
            reference: ref => (ref === null ? 'Ej skapad' : ref)
          }}
          keys={['reference>function:reference', 'status>function:status', 'title', 'payment_left>currency', '_this>function:period']}
          headers={[
            {
              value: 'Referens',
              class: 'min'
            },
            {
              key: 'status',
              value: 'Status',
              sortable: true,
              class: 'min'
            },
            {
              key: 'invoice_type',
              value: 'Typ',
              sortable: true,
              class: 'min'
            },
            {
              key: 'payment_left',
              sortable: true,
              value: 'Kvar att betala'
            },
            {
              key: 'stop',
              sortable: true,
              value: 'Period'
            }
          ]}
        />
        <Pagination data={invoicebases} fetch={fetchInvoicebases} />
      </Card>
      <Modal isOpen={modal} onRequestClose={() => setModal(false)} className='no-padding'>
        <Card
          title='Skapa Underlag'
          icon='fas fa-fw fa-file-invoice'
          buttons={{
            header: [{ callback: () => setModal(false), icon: 'fas fa-times', text: 'Avbryt', bg: 'light' }],
            footer: [<Submit onSubmit={handleSubmit(create)} text='Skapa' />]
          }}>
          <input type='hidden' {...register('lease_id')} value={props.lease.id} />
          <div class='row mb-3'>
            <Date label='Från' controls={{ control }} name='start' error={error} />
          </div>
          <div class='row mb-3'>
            <Date label='Till' controls={{ control }} name='stop' error={error} />
          </div>
        </Card>
      </Modal>
    </>
  )
}
