import React from 'react'

export default function BeefyButton({ _class, type, disabled, _default, callback = false, hover }) {
  return (
    <div class={'component_BeefyButton ' + _class}>
      <div class={`beefy-button beefy-button-${type || 'info'} ${disabled ? 'beefy-disabled' : ''}`} onClick={() => callback && callback()}>
        <div class='icon-wrapper'>
          <i class={`icon-default fa-fw ${_default?.icon}`} />
          <i class={`icon-hover fa-fw ${hover?.icon}`} />
        </div>
        <span class='text-default'>{_default?.text}</span>
        <div class='hover-wrapper'>
          <span>{hover?.text}</span>
        </div>
      </div>
    </div>
  )
}
