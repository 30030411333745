import jwt_decode from 'jwt-decode'
import { authenticationService } from '@enkla-gui'

var billable = authenticationService.currentTokenValue?.access_token ? jwt_decode(authenticationService.currentTokenValue?.access_token).billable : {}
authenticationService.currentToken.subscribe(x => {
  if (x?.access_token) {
    var token = jwt_decode(x.access_token)
    billable = token.billable
    billable.boarded = token.boarded
  }
})

export { billable }
