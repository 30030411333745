
export const integrationhelper = {
	name(name) {
		switch (name) {
			case 'FASDOK':
				return 'Fastighetsägarna Dokument'
			case 'FORTNOX':
				return 'Fortnox'
			case 'VISMA':
				return 'Visma'
			default:
				break
		}
	}
}
