import React from 'react'
import { ViewHeader, Card, Pagination, Table, useGet } from '@enkla-gui'
import { icons } from '@config'

export default function Overview() {
  const { data: companies, sorting, setSorting, setPage } = useGet({ endpoint: 'api/company' })
  return (
    <>
      <ViewHeader
        title='Företag'
        subtitle='Översikt'
        icon={icons.company}
        buttons={[{ icon: icons.create, title: 'Skapa företag', href: '#!/company/create' }]}
      />
      <Card>
        <Table
          rows={companies?.data}
          link={`/#!/company/@{id}`}
          sorting={sorting}
          empty={{
            text: 'Du har inte skapat något företag',
            icon: icons.company
          }}
          useSorting={sort => setSorting({ ...sort })}
          keys={['name', 'organisation_number', 'entities_count>label:light']}
          headers={[
            {
              key: 'name',
              value: 'Namn',
              sortable: true
            },
            {
              key: 'organisation_number',
              value: 'Organisationsnummer',
              sortable: true
            },
            {
              value: 'Objekt'
            }
          ]}
        />
        <Pagination data={companies} fetch={setPage} />
      </Card>
    </>
  )
}
