import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { notify } from '@/_helpers'
import { API, ViewHeader, CheckBox, Card, Submit, Input, Select } from '@enkla-gui'

export default function EntityEdit() {
  const { register, handleSubmit, reset, watch, getValues, setValue } = useForm(false)
  const [error, setError] = useState(null)
  const [entity, setEntity] = useState(null)
  const [sending, setSending] = useState(false)
  const [types, setTypes] = useState(null)
  const { id } = useParams()
  const history = useHistory()

  useEffect(() => {
    API.get(`api/entity/types`).then(types => setTypes(types))
    API.get(`api/entity/${id}`).then(entity => {
      reset(entity)
      setEntity(entity)
    })
  }, [id, reset])

  const onSubmit = data => {
    setSending(true)
    API.patch('api/entity', data)
      .then(res => {
        notify.toast('Ändringar sparade')
        setError(null)
        setSending(false)
        history.push(`/entity/${id}`)
      })
      .catch(([err, res]) => {
        setError({
          message: res.message,
          data: res.data
        })
        setSending(false)
      })
  }

  const printTypeFields = () => {
    if (types === null) return
    var aet = types.filter(e => e.id === entity?.entity_type_id)[0]
    var output = []
    var orderedFields = []

    for (let [key, value] of Object.entries(aet?.fields || [])) {
      orderedFields.push({
        key: key,
        value: value
      })
    }
    if (aet?.rentable === 1) {
      output.push(
        <>
          <div class='col-6'>
            <Input controls={{ register }} name='fee' options={{ required: true }} label='Avgift' type='number' error={error} />
          </div>
          <div class='col-6'>
            <div class='mb-3'>
              <br />
              <CheckBox controls={{ register, getValues, setValue }} name={`fee_as_m2`} options={{ required: false }} label={'Pris per m2'} />
            </div>
          </div>
        </>
      )
    }

    for (let { key, value } of orderedFields) {
      switch (value.type) {
        case 'string':
        case 'number':
          output.push(
            <Input
              error={error}
              controls={{ register }}
              name={`fields.${key}`}
              options={{ required: false }}
              label={value.title}
              type={value.type}
              cols='col-6 mb-3'
            />
          )
          break
        case 'boolean':
          output.push(
            <div class='col-6'>
              <div class='form-group mb-3'>
                <label>{value.title}</label>
                <br />
                <CheckBox error={error} controls={{ register, getValues, setValue }} name={`fields.${key}`} options={{ required: false }} label={value.title} />
              </div>
            </div>
          )
          break
        case 'list':
          output.push(
            <Select
              error={error}
              controls={{ register }}
              name={`fields.${key}`}
              options={{ required: false }}
              label={value.title}
              cols='col-6 mb-3'
              values={value.options.map((option, x) => {
                return {
                  key: option,
                  value: option
                }
              })}
            />
          )
          break
        default:
          break
      }
    }
    return output
  }

  return (
    <>
      <ViewHeader
        loading={!watch('id')}
        title='Objekt'
        icon='fa-layer-group'
        subtitle='Redigera'
        return={{ link: `#!/entity/${id}`, title: getValues('name') }}
        buttons={[{ icon: 'fa-times', title: 'Avbryt', href: `#!/entity/${id}`, position: 'right' }]}
      />
      <Card
        loading={!watch('id')}
        buttons={{
          footer: [<Submit onSubmit={handleSubmit(onSubmit)} text='Spara' sending={sending} />]
        }}>
        <div class='row mb-3'>
          <Input controls={{ register }} name='name' options={{ required: false }} label='Namn' error={error} />
        </div>
        <div class='row mb-3'>
          <Input controls={{ register }} name='address' options={{ required: true }} label='Adress' error={error} />
          <Input controls={{ register }} name='postal_code' options={{ required: true }} label='Postnummer' error={error} />
        </div>
        <div class='row mb-3'>
          <Input controls={{ register }} name='city' options={{ required: true }} label='Stad' error={error} />
          <Input controls={{ register }} name='country' options={{ required: true }} label='Land' error={error} />
        </div>
        <div class='row'>{printTypeFields()}</div>
      </Card>
    </>
  )
}
