import React, { useState, useEffect } from 'react'
import { Card, Pagination, Table, API, notify, Select, Input, Date, Submit } from '@enkla-gui'
import Modal from 'react-modal'
import { useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'

export default function AddonConnectModal({ modal, setModal, id, fetch }) {
  var [addons, setAddons] = useState(null)
  const [sending, setSending] = useState(false)
  let { page } = useParams()
  var [sorting, setSorting] = useState({
    'sort[by]': 'name',
    'sort[order]': 'asc',
    size: 5
  })
  const { register, control, reset, watch, handleSubmit } = useForm({
    id: null
  })
  const [error, setError] = useState(null)

  const fetchAddons = async (p = page || 1) => {
    API.get('api/addon', {
      page: p,
      ...sorting
    }).then(addons => setAddons(addons))
  }
  const connect = data => {
    setSending(true)
    API.post(`api/leaseaddon`, {
      addon_id: data.id,
      lease_id: id,
      start: data.start
    })
      .then(res => {
        notify.toast('Tillägget har kopplats')
        fetch()
        reset({})
        setSending(false)
        setModal(false)
      })
      .catch(([err, res]) => {
        setSending(false)
        setError({
          message: res.message,
          data: res.data
        })
      })
  }

  useEffect(() => {
    fetchAddons()
  }, [sorting])

  return (
    <Modal
      isOpen={modal}
      onRequestClose={() => {
        setModal(false)
        reset({})
      }}
      className='no-padding'>
      <Card
        title='Koppla Tillägg eller Avdrag'
        icon='fas fa-fw fa-receipt'
        buttons={{
          header: [
            {
              callback: () => {
                setModal(false)
                reset({})
              },
              icon: 'fas fa-times',
              text: 'Avbryt',
              bg: 'light'
            }
          ],
          footer: [
            { callback: () => reset({}), icon: 'fas fa-arrow-left', text: 'Tillbaka', bg: 'light', hidden: watch('id') === undefined },
            <Submit onSubmit={handleSubmit(connect)} text='Koppla' sending={sending} hidden={watch('id') === undefined} />
          ]
        }}>
        {watch('id') === undefined && (
          <>
            <Table
              rows={addons?.data}
              sorting={sorting}
              onClick={addon => reset(addon)}
              empty={{
                text: 'Du har inte skapat något tillägg eller avdrag',
                icon: 'fa-receipt'
              }}
              useSorting={sort => setSorting({ ...sort })}
              functions={{
                type: addon => (addon.fee >= 0 ? <span class='badge bg-success w-100'>Tillägg</span> : <span class='badge bg-danger w-100'>Avdrag</span>)
              }}
              keys={['_this>function:type', 'name', 'fee>currency', 'tax_rate>%']}>
              <th class='min'>Typ</th>
              <th>Namn</th>
              <th>Belopp</th>
              <th>Momssats</th>
            </Table>
            <Pagination data={addons} fetch={fetchAddons} />
          </>
        )}
        {watch('id') !== undefined && (
          <>
            <div class='row mb-3'>
              <Input disabled controls={{ register }} name='name' options={{ required: true }} label='Namn' error={error} />
            </div>
            <div class='row mb-3'>
              <Input disabled controls={{ register }} name='fee' options={{ required: true }} type='number' label='Belopp' error={error} />
            </div>
            <div class='row mb-3'>
              <Select
                disabled
                controls={{ register }}
                name='tax_rate'
                options={{ required: true }}
                label='Momssats(%)'
                error={error}
                values={[
                  { key: '0%', value: 0 },
                  { key: '6%', value: 6 },
                  { key: '12%', value: 12 },
                  { key: '25%', value: 25 }
                ]}
              />
            </div>
            <div class='row mb-3'>
              <Date label='Startdatum för debitering' controls={{ control }} name='start' error={error} />
            </div>
          </>
        )}
      </Card>
    </Modal>
  )
}
