import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { notify } from '@/_helpers'
import { API, ViewHeader, Card, Submit, Input, Select, TextArea } from '@enkla-gui'

export default function Ticket() {
  const { register, handleSubmit, reset, watch, getValues } = useForm({})

  const [error, setError] = useState(null)
  const [sending, setSending] = useState(null)
  const { id } = useParams()
  const history = useHistory()

  useEffect(() => {
    API.get(`api/ticket/${id}`).then(ticket => reset(ticket))
  }, [id])

  const update = data => {
    setSending(true)
    API.patch('api/ticket', data)
      .then(res => {
        notify.toast('Ärendet har uppdaterats')
        history.push(`/ticket/${id}`)
      })
      .catch(([err, res]) => {
        setSending(false)
        setError({
          message: res.message,
          data: res.data
        })
      })
  }

  return (
    <>
      <ViewHeader
        loading={!watch('id')}
        title='Ärende'
        subtitle='Redigera'
        icon='fa-envelope-open-text'
        return={{ link: `#!/ticket/${id}`, title: `Ärende · ${getValues('reference')}` }}
        buttons={[{ icon: 'fa-times', title: 'Avbryt', href: `#!/ticket/${id}`, position: 'right' }]}
      />
      <Card
        loading={!watch('id')}
        title='Ärende'
        icon='fas fa-envelope-open-text'
        buttons={{
          footer: [<Submit onSubmit={handleSubmit(update)} text='Spara' sending={sending} />]
        }}>
        <div class='row mb-3'>
          <Input controls={{ register }} name='subject' options={{ required: true }} label='Ämne' error={error} />
          <Select
            controls={{ register }}
            name='type'
            options={{ required: true }}
            label='Ärendestyp'
            error={error}
            values={[
              { key: 'Internt', value: 'INTERNAL' },
              { key: 'Felanmälan', value: 'REPORT' },
              { key: 'Fråga', value: 'QUESTION' }
            ]}
          />
        </div>
        <div class='row'>
          <TextArea controls={{ register }} name='description' options={{ required: true }} label='Beskrivning' error={error} />
        </div>
      </Card>
    </>
  )
}
