import React from 'react'
import { Explination } from '@enkla-gui'

export default function Input({
  controls,
  defaultValue = false,
  name,
  options,
  label,
  type = 'text',
  error,
  disabled = false,
  cols = 'col',
  tip = false,
  placeholder = ''
}) {
  const { register, setValue } = controls
  if (defaultValue) setValue(name, defaultValue)
  var message
  const hasError = () => {
    var data = error.data
    if (data === undefined) return false
    name.split('.').forEach(value => {
      if (value in data) {
        data = data[value]
      } else {
        data = []
      }
    })
    if (name in error.data) {
      console.log(data, error.data)
      data = error.data[name]
    }
    if (data.length === 0) return false
    message = Array.isArray(data) ? data[0] : data
    return true
  }

  return (
    <div class={`component_Input ${cols}`}>
      <div class={`form-group ${disabled ? 'form-group-disabled' : ''}`}>
        <label>
          {label}
          {options?.required && <span class='input-required'> * </span>}
          {tip && <Explination tip={tip} />}
        </label>
        <input {...register(name, options)} type={type} placeholder={placeholder} class='form-control' disabled={disabled} />
        {error && hasError() && (
          <div class='form-inline-error'>
            <i class='fa fa-fw fa-exclamation-triangle' /> {message}
          </div>
        )}
      </div>
    </div>
  )
}
