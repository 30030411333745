import React, { useState, useEffect } from 'react'
import Modal from 'react-modal'
import { Table, NoContentBox } from '@enkla-gui'

export default function SearchModal({ search, open, close, callback }) {
	const [results, setResults] = useState([])
	const [searching, setSearching] = useState(false)
	const [query, setQuery] = useState('')

	useEffect(() => {
		setResults([])
		if (query.trim().length === 0) {
			setResults([])
			setSearching(false)
			return
		}
		if (!query.length !== 0 && !searching) setSearching(true)
		const timeoutId = setTimeout(() => dispatch(query), 500)
		return () => clearTimeout(timeoutId)
	}, [query])

	const dispatch = function(query) {
		search(query)
			.then(response => {
				setSearching(false)
				setResults(response.hits)
			})
			.catch(error => {
				setResults([])
				setSearching(false)
			})
	}

	return (
		<Modal
			isOpen={open}
			onRequestClose={() => {
				close()
				setResults([])
				setSearching(false)
				setQuery('')
			}}
			className='card' >
			<div class='modal-body' style={{ 'background': '#fff' }}>
				<div class='form-group'>
					<input type='text' class='form-control' placeholder='Sök...' onChange={e => setQuery(e.target.value)} />
				</div>
				{results.length !== 0 && (
					<div class='navbar-search-results modal-search-results'>
						<div class='search-clear-results' onClick={e => setResults([])} />
						<div class='card-body'>
							{!!results?.total?.value && (
								<Table
									rows={results.hits}
									functions={{
										title: data => (
											<span>
												<i class={'fas fa-fw ' + data.icon} /> {data.title}
											</span>
										),
										return: value => {
											return (
												<button
													onClick={() => {
														callback(value)
														setResults([])
													}}
													class='btn btn-primary btn-sm text-light mr-5'>
													<i class='fas fa-plus text-light' /> Välj
												</button>
											)
										}
									}}
									keys={['_source.render>function:title', '_source>function:return']}>
									<th></th>
									<th class='min'></th>
								</Table>
							)}
							{results.total?.value === 0 && <NoContentBox text='Inga resultat' icon='fa-info-circle' />}
						</div>
					</div>
				)}
				{searching && (
					<div class='card navbar-search-results' onClick={e => setResults([])}>
						<div class='search-clear-results' />
						<div class='card-body no-padding'>
							<NoContentBox text='Söker...' icon='fa-circle-notch fa-spin' />
						</div>
					</div>
				)}
			</div>
			<div class='card-buttons'>
				<button
					class='btn btn-light'
					onClick={() => {
						close()
						setResults([])
					}}>
					Avbryt
				</button>
			</div>
		</Modal>
	)
}
