// Overlay.js
import React from 'react';


const Overlay = ({ onClick }) => {
	const overlayStyle = {
		position: 'fixed',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		zIndex: 10000
	};

	return <div style={overlayStyle} onClick={onClick} />;
};
export default Overlay;
