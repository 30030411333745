import React from 'react'
import { Explination } from '@enkla-gui'

export default function TextArea({ controls, name, options, label, error, rows = '10', disabled = false, cols = 'col', tip = false }) {
  const { register } = controls
  var message
  const hasError = () => {
    var data = error.data
    if (data === undefined) return false
    name.split('.').forEach(value => {
      if (value in data) {
        data = data[value]
      } else {
        data = []
      }
    })
    if (data.length === 0) return false
    message = data[0]
    return true
  }
  return (
    <div class={`component_TextArea ${cols}`}>
      <div class={`form-group ${disabled ? 'form-group-disabled' : ''}`}>
        <label>
          {label}
          {options?.required && <span class='input-required'> * </span>}
          {tip && <Explination tip={tip} />}
        </label>
        <textarea rows={rows} {...register(name, options)} type='text' disabled={disabled} class='form-control' />
        {error && hasError() && (
          <div class='form-inline-error'>
            <i class='fa fa-fw fa-exclamation-triangle' />
            {message}
          </div>
        )}
      </div>
    </div>
  )
}
