import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { tickethelper } from '@/_helpers'
import { useGet, ViewHeader, InfoBox, Table, Pagination, Card, Filter } from '@enkla-gui'
import { filters } from '@/_config'

export default function Tickets() {
  const { status } = useParams()
  const { data: tickets, setSorting, setPage, setFilter, sorting, loading } = useGet({ endpoint: `api/ticket`, filter: status ? { status: status } : {} })
  const { data: billable } = useGet({ endpoint: `api/auth/billable` })
  const [showFilter, setShown] = useState(false)

  useEffect(() => {
    if (status) setFilter({ status: status })
  }, [status, setFilter])

  return (
    <>
      <ViewHeader
        icon='fa-envelope-open-text'
        title='Ärenden'
        subtitle={status === undefined ? 'Översikt' : tickethelper.status(status.toUpperCase()).label}
        buttons={[{ icon: 'fa-filter', title: `${showFilter ? 'Dölj' : 'Visa'} filter`, callback: () => setShown(!showFilter), hidden: status }]}
      />
      <div class='row'>
        <InfoBox loading={!tickets} title='Nya ärenden' subtitle={billable?.stat_new_tickets} icon='fa-bullhorn' />
        <InfoBox loading={!tickets} title='Öppna ärenden' subtitle={billable?.stat_open_tickets} icon='fa-clock' />
        <InfoBox loading={!tickets} title='Avklarade denna månad' subtitle={billable?.stat_resolved_tickets_month} icon='fa-check-square' />
      </div>
      <Filter show={showFilter} filters={filters.ticket} useFilter={setFilter} />
      <Card>
        <Table
          loadercount={15}
          fetching={loading}
          rows={tickets?.data}
          link={`/#!/ticket/@{id}`}
          sorting={sorting}
          empty={{
            text: `Det finns inte några ${status === undefined ? '' : tickethelper.status(status.toUpperCase()).title} ärenden`,
            icon: 'fa-key'
          }}
          useSorting={sort => setSorting({ ...sort })}
          functions={{
            getStatus: status => <span class={`badge w-100 bg-${tickethelper.status(status).color}`}>{tickethelper.status(status).label}</span>,
            entity: ticket =>
              ticket.entity !== null ? (
                <a class='text-decoration-none' onClick={e => e.stopPropagation()} href={`/#!/entity/${ticket.entity.id}/`}>
                  <i class={`fa ${ticket.entity.type.icon}`} /> {ticket.entity.name}
                </a>
              ) : (
                ''
              ),
            tenant: ticket =>
              ticket.tenant !== null ? (
                <a class='text-decoration-none' onClick={e => e.stopPropagation()} href={`/#!/tenant/${ticket.tenant.id}/`}>
                  {' '}
                  {ticket.tenant.name}
                </a>
              ) : (
                ''
              ),
            getType: type => <span>{tickethelper.type(type)}</span>
          }}
          keys={[
            'reference',
            'status>function:getStatus',
            'type>function:getType',
            'subject',
            '_this>function:entity',
            '_this>function:tenant',
            'created_at>date:full'
          ]}
          headers={[
            {
              key: 'reference',
              value: '#',
              sortable: true,
              class: 'min'
            },
            {
              key: 'status',
              value: 'Status',
              sortable: true,
              class: 'min'
            },
            {
              key: 'type',
              value: 'Ärendetyp',
              sortable: true,
              class: 'min'
            },
            {
              key: 'subject',
              value: 'Ämne',
              sortable: true
            },
            {
              value: 'Objekt',
              class: 'min'
            },
            {
              value: 'Hyresgäst',
              class: 'min'
            },
            {
              key: 'created_at',
              value: 'Skapad',
              defaultsort: true,
              sortable: true
            }
          ]}
        />
        <Pagination data={tickets} fetch={setPage} pushToHistory />
      </Card>
    </>
  )
}
