import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { notify } from '@/_helpers'
import { API, ViewHeader, CheckBox, Card, Submit, Input, Select, Help } from '@enkla-gui'

export default function CreateEntity() {
  const { register, handleSubmit, reset, getValues, setValue } = useForm()
  const [types, setTypes] = useState(false)
  const [entities, setEntities] = useState(false)
  const [activeType, setActiveType] = useState(null)
  const [parent, setParent] = useState({})
  const [error, setError] = useState(null)
  const [sending, setSending] = useState(false)
  const { parentType, parentId } = useParams()
  const history = useHistory()

  useEffect(() => {
    API.get('api/entity').then(entities => setEntities(entities))

    API.get(`api/${parentType}/${parentId}`).then(parent => {
      setParent(parent)
      reset({
        address: parent.address,
        postal_code: parent.postal_code,
        city: parent.city,
        country: parent.country || 'Sverige',
        company_id: parentType === 'company' ? parentId : parent.company_id,
        parent_id: parentType === 'entity' ? parentId : null
      })
      API.get(`api/entity/types`).then(types => {
        if (parentType === 'company') return setTypes(types)
        setTypes(types.filter(type => type.level > parent?.type?.level))
      })
    })
  }, [parentId, parentType, reset])

  const onSubmit = data => {
    setSending(true)
    API.post('api/entity', data)
      .then(res => {
        notify.toast(`${res.entity.name} skapad`)
        history.push({ pathname: `/${parentType}/${parent.id}` })
        setSending(false)
      })
      .catch(([err, res]) => {
        setError({
          message: res.message,
          data: res.data
        })
        setSending(false)
      })
  }
  const printTypeFields = () => {
    var aet = types.filter(e => e.id === activeType)[0]
    var fields = aet?.fields
    var output = []
    var bools = []
    var orderedFields = []
    if (aet?.rentable === 1) {
      output.push(
        <>
          <div class='col-6'>
            <Input controls={{ register }} name='fee' options={{ required: true }} label='Avgift' type='number' error={error} />
          </div>
          <div class='col-6'>
            <div class='mb-3'>
              <br />
              <CheckBox controls={{ register, getValues, setValue }} name={`fee_as_m2`} options={{ required: false }} label={'Pris per m2'} />
            </div>
          </div>
        </>
      )
    }

    for (let [key, value] of Object.entries(fields)) {
      orderedFields.push({
        key: key,
        value: value
      })
    }

    orderedFields = orderedFields.sort((a, b) => a.value.order - b.value.order)
    for (let { key, value } of orderedFields) {
      switch (value.type) {
        case 'string':
        case 'number':
          output.push(
            <Input
              controls={{ register }}
              error={error}
              name={`fields.${key}`}
              options={{ required: false }}
              label={value.title}
              type={value.type}
              cols='col-6 mb-3'
            />
          )
          break
        case 'boolean':
          bools.push(
            <div class='col-6'>
              <div class='form-group mb-3'>
                <label>{value.title}</label>
                <br />
                <CheckBox controls={{ register, getValues, setValue }} name={`fields.${key}`} options={{ required: false }} label={value.title} />
              </div>
            </div>
          )
          break
        case 'list':
          output.push(
            <Select
              controls={{ register }}
              name={`fields.${key}`}
              options={{ required: false }}
              label={value.title}
              cols='col-6 mb-3'
              values={value.options.map((option, x) => {
                return {
                  key: option,
                  value: option
                }
              })}
            />
          )
          break
        default:
          break
      }
    }
    return output.concat(bools)
  }
  return (
    <>
      <ViewHeader
        loading={!parent.id}
        title={parent.name}
        icon={parentType === 'company' ? 'fa-briefcase' : parent.type?.icon}
        subtitle='Skapa objekt'
        return={{ link: `#!/${parentType}/${parent.id}`, title: parent.name }}
        buttons={[]}
      />
      {entities.total === 0 && (
        <Help type='info'>
          <span>Fastigheter är en överordnad objektstyp som ej går att hyra ut.</span>
        </Help>
      )}
      <Card
        loading={!parent.id}
        buttons={{
          footer: [<Submit onSubmit={handleSubmit(onSubmit)} text='Skapa' sending={sending} />]
        }}>
        <div class='row mb-3'>
          {types && types.length !== 0 && (
            <div class='form-group'>
              <label>Typ</label>
              <select {...register('entity_type_id', { required: true })} onChange={e => setActiveType(e.target.value)} class='form-select'>
                <option value=''>Välj en typ...</option>
                {types
                  .sort((a, b) => a.level - b.level)
                  .map((type, x) => (
                    <option value={type.id}>
                      {[...Array(type.level)].map(x => '-')} {type.name}
                    </option>
                  ))}
              </select>
              {error?.data?.type && (
                <div class='form-inline-error'>
                  <i class='fa fa-fw fa-exclamation-triangle' /> {error.data.type[0]}{' '}
                </div>
              )}
            </div>
          )}
          {types.length === 0 && <input type='text' class='form-control' disabled />}
        </div>
        <div class='row mb-3'>
          <Input controls={{ register }} name='name' options={{ required: true }} label='Namn' error={error} />
        </div>
        <div class='row mb-3'>
          <Input controls={{ register }} name='address' options={{ required: true }} label='Adress' error={error} />
          <Input controls={{ register }} name='postal_code' options={{ required: true }} label='Postnummer' error={error} />
        </div>
        <div class='row mb-3'>
          <Input controls={{ register }} name='city' options={{ required: true }} label='Stad' error={error} />
          <Input controls={{ register }} name='country' options={{ required: true }} label='Land' error={error} />
        </div>
        <div class='row'>{activeType && printTypeFields()}</div>
      </Card>
    </>
  )
}
