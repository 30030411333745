import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { notify } from '@/_helpers'
import { API, Submit, Card, Input, Select, Table } from '@enkla-gui'
import Modal from 'react-modal'

export default function InvoiceRows(props) {
  const { register, handleSubmit } = useForm()
  const { register: updateRegister, handleSubmit: updateSubmit, reset: updateReset } = useForm()
  const [error, setError] = useState(null)
  const [sending, setSending] = useState(false)
  const [invoicebase, setInvoicebase] = useState(false)
  const [updateModal, setUpdateModal] = useState(false)
  const [modal, setModal] = useState(false)

  const { id } = useParams()

  useEffect(() => {
    setInvoicebase(props.invoicebase)
  }, [props.invoicebase])

  const create = data => {
    setSending(true)
    API.post('api/invoicerow', data)
      .then(res => {
        notify.toast('Fakturarad skapats')
        setSending(false)
        setModal(false)
        props.fetch()
      })
      .catch(([err, res]) => {
        setError({
          message: res.message,
          data: res.data
        })
        setSending(false)
      })
  }

  const update = data => {
    setSending(true)
    API.patch(`api/invoicerow`, data)
      .then(res => {
        notify.toast(`Fakturaraden har uppdaterats`)
        setUpdateModal(false)
        setSending(false)
        props.fetch()
      })
      .catch(([err, res]) => {
        setError({
          message: res.message,
          data: res.data
        })
        setSending(false)
      })
  }

  const remove = async row => {
    if (!(await notify.confirm())) return
    API.delete(`api/invoicerow/${row.id}`)
      .then(res => {
        notify.toast(`Fakturaraden har raderats`)
        props.fetch()
      })
      .catch(([err, res]) => {
        notify.toast(`Borttagningen misslyckades: ${res.message}`, 'error')
      })
  }

  return (
    <>
      <Card
        title='Fakturarader'
        icon='fas fa-stream'
        buttons={{
          header: [{ callback: () => setModal(true), icon: 'fas fa-plus-square', text: 'Lägg till rad', hidden: invoicebase.locked_at !== null }]
        }}>
        <Table
          rows={invoicebase.rows}
          functions={{
            remove: row =>
              invoicebase.locked_at === null ? (
                <button onClick={() => remove(row)} class='btn btn-sm btn-light'>
                  <i class='fas fa-fw fa-trash'></i>
                </button>
              ) : (
                ''
              ),
            edit: row =>
              invoicebase.locked_at === null ? (
                <button
                  onClick={() => {
                    updateReset(row)
                    setUpdateModal(true)
                  }}
                  class='btn btn-sm btn-light'>
                  <i class='fas fa-fw fa-edit'></i>
                </button>
              ) : (
                ''
              )
          }}
          keys={['_this>function:edit', 'name', `fee>currency`, 'quantity', 'unit', 'tax_rate>%', `tax>currency`, '_this>function:remove']}>
          <th class='min' />
          <th>Artikel</th>
          <th>Pris</th>
          <th>Antal</th>
          <th>Enhet</th>
          <th>Momssats</th>
          <th>Moms</th>
          <th class='min' />
        </Table>
      </Card>
      <Modal isOpen={modal} onRequestClose={() => setModal(false)} className='no-padding'>
        <Card
          title='Skapa Artikel'
          icon='fas fa-stream'
          buttons={{
            header: [{ callback: () => setModal(false), icon: 'fas fa-times', text: 'Avbryt', bg: 'light' }],
            footer: [<Submit onSubmit={handleSubmit(create)} text='Skapa' sending={sending} />]
          }}>
          <input type='hidden' {...register('invoicebase_id')} value={id} />
          <div class='row mb-3'>
            <Input controls={{ register }} name='name' options={{ required: true }} label='Namn' error={error} />
          </div>
          <div class='row mb-3'>
            <Input controls={{ register }} name='quantity' options={{ required: true }} label='Antal' type='number' error={error} />
          </div>
          <div class='row mb-3'>
            <Input controls={{ register }} name='fee' options={{ required: true }} label='Pris' error={error} />
          </div>
          <div class='row mb-3'>
            <Select
              controls={{ register }}
              name='tax_rate'
              options={{ required: true }}
              label='Momssats (%)'
              error={error}
              values={[
                { key: '0%', value: 0 },
                { key: '6%', value: 6 },
                { key: '12%', value: 12 },
                { key: '25%', value: 25 }
              ]}
            />
          </div>
        </Card>
      </Modal>
      <Modal isOpen={updateModal} onRequestClose={() => setUpdateModal(false)} className='no-padding'>
        <Card
          title='Redigera Artikel'
          icon='fas fa-stream'
          buttons={{
            header: [{ callback: () => setUpdateModal(false), icon: 'fas fa-times', text: 'Avbryt', bg: 'light' }],
            footer: [<Submit onSubmit={updateSubmit(update)} text='Uppdatera' sending={sending} />]
          }}>
          <div class='row mb-3'>
            <Input controls={{ register: updateRegister }} name='name' options={{ required: true }} label='Namn' error={error} />
          </div>
          <div class='row mb-3'>
            <Input controls={{ register: updateRegister }} name='quantity' options={{ required: true }} label='Antal' error={error} />
          </div>
          <div class='row mb-3'>
            <Input controls={{ register: updateRegister }} name='fee' options={{ required: true }} label='Pris' error={error} />
          </div>
          <div class='row mb-3'>
            <Select
              controls={{ register: updateRegister }}
              name='tax_rate'
              options={{ required: true }}
              label='Momssats (%)'
              error={error}
              values={[
                { key: '0%', value: 0 },
                { key: '6%', value: 6 },
                { key: '12%', value: 12 },
                { key: '25%', value: 25 }
              ]}
            />
          </div>
        </Card>
      </Modal>
    </>
  )
}
