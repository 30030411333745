import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { ViewHeader, Card, Submit, Input, useGet, useSubmit, TextArea, notify } from '@enkla-gui'
import { icons } from '@config'
import MaintenanceTasks from '@components/maintenance/MaintenanceTasks'
import { BehaviorSubject } from 'rxjs'

export default function MaintenanceTemplateEditView() {
  const { id } = useParams()
  const history = useHistory()

  const { data: template } = useGet({ endpoint: `api/maintenance/template/${id}` })
  const [tasks, setTasks] = useState(false)
  useEffect(() => {
    if (template) {
      const parsedTasks = new BehaviorSubject(template.tasks)
      setTasks(parsedTasks)
    }
  }, [template])
  const { register, submit, error, loading, getValues, setValue } = useSubmit({
    endpoint: 'api/maintenance/template',
    form: template,
    method: 'patch',
    success: () => history.push(`/maintenance/template/${id}`)
  })

  return (
    <>
      <ViewHeader
        loading={!template}
        title='Rutin'
        icon={icons.maintenance}
        subtitle='Redigera'
        return={{ link: `#!/maintenance/template/${id}`, title: getValues('title') }}
        buttons={[{ icon: icons.cancel, title: 'Avbryt', href: `#!/maintenance/template/${id}`, position: 'right' }]}
      />
      <div class='row'>
        <Card loading={!template}>
          <div class='row guide-step-1 mb-3'>
            <div class='col'>
              <Input controls={{ register }} name='title' options={{ required: true }} label='Namn' error={error} />
            </div>
            <div class='col'>
              <Input
                controls={{ register }}
                type='number'
                name='reschedule_in'
                options={{ required: false }}
                label='Återkommande efter antal dagar'
                error={error}
              />
            </div>
          </div>
          <div class='row'>
            <div class='col'>
              <TextArea controls={{ register }} name='description' options={{ required: true }} label='Beskrivning' error={error} />{' '}
            </div>
          </div>
        </Card>
      </div>
      <div class='row'>
        <Card loading={!template}>{tasks && <MaintenanceTasks tasks={tasks} />}</Card>
      </div>
      <div class='d-grid gap-2'>
        <Submit
          onSubmit={async () => {
            if (!(await notify.confirm('Om du uppdaterar denna rutin kommer även alla aktiviteter uppdateras'))) return
            setValue('tasks', tasks.getValue())
            submit()
          }}
          text='Uppdatera rutin'
          sending={loading}
        />
      </div>
    </>
  )
}
