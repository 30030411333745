import React from 'react'
import { useParams } from 'react-router-dom'
import { useGet, ViewHeader, Table, Pagination, Card } from '@enkla-gui'

export default function Entities() {
  const { type } = useParams()
  const getType = function () {
    switch (type) {
      case 'premises':
        return {
          title: 'Lokaler',
          rentable: true,
          type: '/type/90cd5c02-505c-4a31-b4b0-6e65d0a1a5e7'
        }
      case 'apartments':
        return {
          title: 'Lägenheter',
          rentable: true,
          type: '/type/0f860f6d-8aca-4bdb-815c-8eb42490d689'
        }
      case 'parkings':
        return {
          title: 'Parkeringar',
          rentable: true,
          type: '/type/56522511-2847-4742-a65f-0c997fea7d1c'
        }
      case 'vacancies':
        return {
          title: 'Vakanser',
          rentable: true,
          type: '/type/vacancies'
        }
      case 'estates':
        return {
          title: 'Fastigheter',
          rentable: false,
          type: '/type/beaa54cd-ae29-40e2-8c0a-8084ad5ab35b'
        }
      default:
        return {
          title: 'Objekt',
          rentable: true,
          type: ''
        }
    }
  }

  const { data: entities, setSorting, setPage, sorting } = useGet({ endpoint: `api/entity${getType().type}` })
  return (
    <>
      <ViewHeader icon='fa-layer-group' title={getType().title} subtitle='Översikt' />
      <Card>
        <Table
          loadercount={15}
          rows={entities?.data}
          link={`/#!/entity/@{id}`}
          sorting={sorting}
          useSorting={sort => setSorting({ ...sort })}
          empty={{
            text:
              getType().title !== 'Vakanser'
                ? 'Här var det tomt! För att skapa upp ett objekt utgå från ett företag eller ett befintligt objekt.'
                : 'Det finns inte några vakanser',
            icon: 'fa-layer-group'
          }}
          functions={{
            getType: type => (
              <div class='icon-badge'>
                <i class={`fas fa-fw ${type.icon}`} /> <span>{type.name}</span>{' '}
              </div>
            ),
            getStatus: entity =>
              entity.type.rentable ? <span class={`w-100 badge ${entity.rented ? 'bg-success' : 'bg-light'}`}>{entity.rented ? 'Uthyrd' : 'Vakant'}</span> : '',
            getParent: entity => {
              if (entity.parent) {
                return (
                  <a onClick={e => e.stopPropagation()} href={`/#!/entity/${entity.parent.id}`}>
                    {entity.parent.name}
                  </a>
                )
              }
              if (entity.company) {
                return (
                  <a onClick={e => e.stopPropagation()} href={`/#!/company/${entity.company.id}`}>
                    {entity.company.name}
                  </a>
                )
              }
              return ''
            }
          }}
          keys={['name', 'type>function:getType', '_this>function:getParent', '_this>function:getStatus', 'created_at>date:short']}
          headers={[
            {
              key: 'name',
              value: 'Namn',
              sortable: true,
              class: 'min'
            },
            {
              key: 'entity_type_id',
              value: 'Typ',
              sortable: true,
              class: 'min'
            },
            {
              value: 'Kopplat till'
            },
            {
              value: 'Status',
              class: 'min'
            },
            {
              key: 'created_at',
              value: 'Skapad',
              sortable: true,
              class: 'min'
            }
          ]}
        />
        <Pagination data={entities} fetch={setPage} pushToHistory />
      </Card>
    </>
  )
}
