const core = [
	{
		path: '/login',
		public: true,
		component: require('@views/auth/login')
	},
	{
		path: '/register',
		public: true,
		component: require('@views/auth/register')
	},
	{
		path: '/forgot',
		public: true,
		component: require('@views/auth/forgot')
	},
	{
		path: '/reset/:token',
		public: true,
		component: require('@views/auth/reset')
	},
	{
		path: '/dashboard',
		component: require('@views/core/dashboard')
	},
	{
		path: '/calendar',
		component: require('@views/core/calendar')
	},
	{
		path: '/onboard',
		component: require('@views/core/onboard')
	},
	{
		path: '/economy',
		component: require('@views/economy/overview')
	},
	{
		path: '/file',
		component: require('@views/core/file_overview')
	},
	{
		path: '/help/contact',
		component: require('@views/help/contact')
	},
	{
		path: '/help/faq',
		component: require('@views/help/faq')
	},
	{
		path: '/help/about',
		component: require('@views/help/about')
	},
	{
		path: '/contact/:type/:id',
		component: require('@views/contact/contact')
	},
	{
		path: '/change/email/:token',
		component: require('@views/auth/change_email')
	}
]
const invoicebase = [
	{
		path: ['/invoicebase/overview', '/invoicebase/overview/:page'],
		exact: true,
		component: require('@views/invoicebase/overview')
	},
	{
		path: ['/invoicebase/status/:status', '/invoicebase/status/:status/:page'],
		exact: true,
		component: require('@views/invoicebase/overview')
	},
	{
		path: '/invoicebase/:id',
		exact: true,
		component: require('@views/invoicebase/invoicebase')
	}
]
const lease = [
	{
		path: '/lease/create/entity/:id',
		exact: true,
		component: require('@views/lease/create')
	},
	{
		path: ['/lease/overview', '/lease/overview/:page'],
		exact: true,
		component: require('@views/lease/overview')
	},
	{
		path: ['/lease/filter/:filter', '/lease/filter/:filter/:page'],
		exact: true,
		component: require('@views/lease/overview')
	},
	{
		path: '/lease/:id/edit',
		exact: true,
		component: require('@views/lease/edit')
	},
	// {
	//   path: '/lease/:id/bill',
	//   exact: true,
	//   component: require('@views/lease/bill')
	// },
	{
		path: '/lease/:id',
		exact: true,
		component: require('@views/lease/lease')
	},
	{
		path: '/lease/:id/tenant/create',
		exact: true,
		component: require('@views/lease/create_tenant')
	}
]
const ticket = [
	{
		path: '/ticket/create/:type/:id',
		exact: true,
		component: require('@views/ticket/create')
	},
	{
		path: ['/ticket/overview', '/ticket/overview/:page'],
		exact: true,
		component: require('@views/ticket/overview')
	},
	{
		path: ['/ticket/status/:status', '/ticket/status/:status/:page'],
		exact: true,
		component: require('@views/ticket/overview')
	},
	{
		path: '/ticket/:id',
		exact: true,
		component: require('@views/ticket/ticket')
	},
	{
		path: '/ticket/:id/edit',
		exact: true,
		component: require('@views/ticket/edit')
	}
]
const entity = [
	{
		path: ['/entity/overview', '/entity/overview/:page'],
		exact: true,
		component: require('@views/entity/overview')
	},
	{
		path: ['/entity/type/:type', '/entity/type/:type/:page'],
		exact: true,
		component: require('@views/entity/overview')
	},
	{
		path: '/entity/create/:parentType/:parentId',
		component: require('@views/entity/create')
	},
	{
		path: '/entity/:id',
		exact: true,
		component: require('@views/entity/entity')
	},
	{
		path: '/entity/:id/edit',
		exact: true,
		component: require('@views/entity/edit')
	}
]
const company = [
	{
		path: '/company',
		exact: true,
		component: require('@views/company/overview')
	},
	{
		path: '/company/create',
		exact: true,
		component: require('@views/company/create')
	},
	{
		path: '/company/:id/invoicingconfig',
		exact: true,
		component: require('@views/company/invoicing')
	},
	{
		path: '/company/:id',
		exact: true,
		component: require('@views/company/company')
	},
	{
		path: '/company/:id/edit',
		exact: true,
		component: require('@views/company/edit')
	}
]
const tenant = [
	{
		path: ['/tenant/overview', '/tenant/overview/:page'],
		exact: true,
		component: require('@views/tenant/overview')
	},
	{
		path: '/tenant/:id',
		exact: true,
		component: require('@views/tenant/tenant')
	},
	{
		path: '/tenant/:id/edit',
		exact: true,
		component: require('@views/tenant/edit')
	},
	{
		path: '/address/create/tenant/:id',
		component: require('@views/tenant/create_address')
	}
]

const integrate = [
	//integrate-routes får heta integrate istället för integration då vi confat upp redirect url hos visma
	{
		path: '/integrate/fortnox',
		component: require('@views/integration/fortnox')
	},
	{
		path: '/integrate/visma',
		component: require('@views/integration/visma')
	},
	{
		path: '/integration/overview',
		component: require('@views/integration/overview')
	},

]
const addon = [
	{
		path: '/addon',
		exact: true,
		component: require('@views/addon/overview')
	},
	{
		path: '/addon/create',
		exact: true,
		component: require('@views/addon/create')
	},
	{
		path: '/addon/:id',
		exact: true,
		component: require('@views/addon/addon')
	},
	{
		path: '/addon/:id/edit',
		exact: true,
		component: require('@views/addon/edit')
	}
]

const setting = [
	{
		path: '/setting/billable',
		component: require('@views/settings/billable')
	},
	{
		path: '/setting/user',
		component: require('@views/settings/user')
	},
	{
		path: '/setting/membership',
		component: require('@views/settings/membership')
	},
	{
		path: '/setting/tenantportal',
		component: require('@views/settings/tenantportal')
	}
]

const maintenance = [
	{
		path: '/maintenance/template/overview',
		component: require('@views/maintenance/template/overview')
	},
	{
		path: '/maintenance/template/create',
		component: require('@views/maintenance/template/create')
	},
	{
		path: '/maintenance/template/:id',
		exact: true,
		component: require('@views/maintenance/template/template')
	},
	{
		path: '/maintenance/template/:id/edit',
		exact: true,
		component: require('@views/maintenance/template/edit')
	},
	{
		path: '/:type/:id/maintenance/create',
		exact: true,
		component: require('@views/maintenance/create')
	},
	{
		path: '/maintenance/overview',
		exact: true,
		component: require('@views/maintenance/overview')
	},
	{
		path: '/maintenance/:id',
		exact: true,
		component: require('@views/maintenance/maintenance')
	},
	{
		path: '/maintenance/:id/edit',
		exact: true,
		component: require('@views/maintenance/edit')
	}
]

const routes = [...core, ...lease, ...ticket, ...entity, ...company, ...tenant, ...setting, ...invoicebase, ...integrate, ...addon, ...maintenance]
export { routes }
