import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { notify } from '@/_helpers'
import { API, ViewHeader, Card, Submit, Input, Select, Help } from '@enkla-gui'

export default function Billable() {
  const { register, handleSubmit, watch, reset, getValues } = useForm()
  const [error, setError] = useState(null)
  const [sending, setSending] = useState(false)
  const [help, setHelp] = useState(false)

  useEffect(() => {
    getBillable()
  }, [])

  const getBillable = () => {
    API.get('api/billable').then(billable => {
      reset(billable)
      setHelp(billable.identifier === null)
    })
  }

  const onSubmit = async data => {
    setSending(true)
    API.patch('api/billable', data)
      .then(res => {
        notify.toast('Ändringar sparade')
        setError(null)
        setSending(false)
        getBillable()
      })
      .catch(([err, res]) => {
        setError({
          message: res.message,
          data: res.data
        })
        setSending(false)
      })
  }

  return (
    <>
      <ViewHeader
        icon='fa-briefcase'
        title='Inställningar'
        subtitle='Verksamhet'
        badges={[{ bg: 'success', text: getValues('organisation') ? 'Företagskund' : 'Privatkund' }]}
      />
      {help && (
        <Help type='info'>
          <span>Du behöver komplettera dina uppgifter.</span>
        </Help>
      )}
      <Card
        loading={!watch('id')}
        buttons={{
          footer: [<Submit onSubmit={handleSubmit(onSubmit)} text='Spara' sending={sending} />]
        }}>
        <div class='row mb-3'>
          <Input controls={{ register }} name='name' options={{ required: true }} label='Verksamhetsnamn' error={error} />
        </div>
        <div class='row mb-3'>
          <Select
            controls={{ register }}
            name='currency'
            options={{ required: true }}
            label='Valuta'
            error={error}
            values={[
              { key: 'SEK', value: 'SEK' }
              // {key: 'EUR', value: 'EUR'},
              // {key: 'NOK', value: 'NOK'},
              // {key: 'DKK', value: 'DKK'},
              // {key: 'USD', value: 'USD'}
            ]}
          />
        </div>
        <div class='row'>
          <Input controls={{ register }} name='identifier' options={{ required: true }} label='Organisationsnummer/Personnummer' error={error} />
          <Input controls={{ register }} name='phone' options={{ required: false }} label='Telefonnummer' error={error} />
        </div>
      </Card>
    </>
  )
}
