import moment from 'moment'

export const date = {
  short: function (value) {
    return moment.unix(value).locale('sv').format('YYYY-MM-DD')
  },
  month: function (value) {
    return moment(value).locale('sv').format('YYYY-MM')
  },
  full: function (value) {
    var dAtE = moment.unix(value).locale('sv').format('YYYY-MM-DD HH:mm')
    if (dAtE === 'Invalid date') return moment(value).locale('sv').format('YYYY-MM-DD HH:mm')
    return dAtE

  },
  since: function (value) {
    return moment.unix(value).locale('sv').fromNow()
  },
  now: function () {
    return moment().locale('sv').unix()
  },
  unix: function (value) {
    return moment().unix(value)
  },
  today: function () {
    return moment().startOf('day').utcOffset(0, true).unix()
  },
  fixTimezone (value) {
    if (value === null) return value
    //datumet kommer som +0100 dvs kommer aktiveras 23:00 dagen innan valt datum och det här är en riktigt solid workaround
    return new Date(moment(value).format('YYYY-MM-DD'))
  }
}
