const filters = {
  ticket: [
    {
      type: 'input',
      label: 'Ärendenummer',
      key: 'reference'
    },
    {
      type: 'select',
      label: 'Status',
      key: 'status',
      values: [
        {
          label: 'Pågående',
          value: 'OPEN'
        },
        {
          label: 'Löst',
          value: 'RESOLVED'
        },
        {
          label: 'Ny',
          value: 'NEW'
        }
      ]
    },
    {
      type: 'select',
      label: 'Ärendetyp',
      key: 'type',
      values: [
        {
          value: 'QUESTION',
          label: 'Fråga'
        },
        {
          value: 'REPORT',
          label: 'Felanmälan'
        }
      ]
    }
  ],
  invoice: [
    {
      type: 'input',
      label: 'Referens',
      key: 'reference'
    },
    {
      type: 'daterange',
      label: 'Period',
      key: 'period'
    },
    {
      type: 'select',
      label: 'Status',
      key: 'status',
      values: [
        {
          label: 'Redigeras',
          value: 'EDIT'
        },
        {
          label: 'Attestering',
          value: 'REVIEW'
        },
        {
          label: 'Redo att skickas',
          value: 'READY'
        },
        {
          label: 'Skickad',
          value: 'SENT'
        },
        {
          label: 'Betald',
          value: 'PAYED'
        },
        {
          label: 'Delbetald',
          value: 'PARTIALLY_PAYED'
        },
        {
          label: 'Förfallen',
          value: 'OVERDUE'
        },
        {
          label: 'Makulerad',
          value: 'CANCELLED'
        },
        {
          label: 'Krediterad',
          value: 'CREDITED'
        }
      ]
    }
  ],
  maintenance: [
    {
      type: 'input',
      label: 'Aktivitet',
      key: 'title'
    },
    {
      type: 'select',
      label: 'Status',
      key: 'status',
      values: [
        {
          label: 'Pågår',
          value: 'IN_PROGRESS'
        },
        {
          label: 'Klar',
          value: 'DONE'
        },
        {
          label: 'Förfallen',
          value: 'OVERDUE'
        },
        {
          label: 'Makulerad',
          value: 'CANCELLED'
        },
        {
          label: 'Planerad',
          value: 'PLANNED'
        }
      ]
    }
  ]
}
export { filters }
