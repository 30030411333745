import './styles.scss'

import React, { useEffect, useState } from 'react'

import { NoContentBox } from '@enkla-gui'
import { v4 as uuidv4 } from 'uuid'
export default function MaintenanceTasks({ tasks, previewMode = false }) {
  const [inputValue, setInputValue] = useState('')
  const [tasksState, setTasksState] = useState([])

  useEffect(() => {
    const subscription = tasks.subscribe(newTasks => {
      setTasksState(newTasks)
    })

    return () => {
      subscription.unsubscribe()
    }
  }, [tasks])

  const addTask = () => {
    if (inputValue.length === 0) return
    const newTasks = [
      ...tasks.value,
      {
        id: uuidv4(),
        status: 'INCOMPLETE',
        task: inputValue
      }
    ]
    tasks.next(newTasks)
    setInputValue('')
  }

  const deleteTask = id => {
    const updatedTasks = tasks.value.filter(task => task.id !== id)
    tasks.next(updatedTasks)
  }
  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      addTask()
    }
  }
  return (
    <div className='component_MaintenanceTasks'>
      {!previewMode && (
        <div className='input-group'>
          <input
            onKeyPress={handleKeyPress}
            type='text'
            placeholder=''
            className='form-control'
            value={inputValue}
            onChange={e => setInputValue(e.target.value)}
          />

          <div className='input-group-append'>
            <button className='btn btn-primary' onClick={() => addTask()}>
              <i className='fas fa-plus' />
            </button>
          </div>
        </div>
      )}
      <div className='tasks-wrapper'>
        {tasksState.map((task, index) => (
          <div key={index} className='taskerino-wrapper'>
            <div className='task-task'>{task.task}</div>
            {!previewMode && (
              <button onClick={() => deleteTask(task.id)} class='icon-link'>
                <i class='fas fa-trash' />
              </button>
            )}
          </div>
        ))}
        {tasksState.length === 0 && (
          <NoContentBox text={previewMode ? 'Det finns inte några uppgifter' : 'Börja lägga till uppgifter genom att fylla i fältet ovan'} icon={'fa-list'} />
        )}
      </div>
    </div>
  )
}
