import { authenticationService } from './authentication.service'

export default function authHeader() {
  const currentToken = authenticationService.currentTokenValue
  if (currentToken && currentToken.access_token) {
    return { Authorization: `Bearer ${currentToken.access_token}` }
  } else {
    return {}
  }
}
