import React from 'react'
import ContentLoader from 'react-content-loader'
//https://skeletonreact.com/
export default function InfoBoxLoader(props) {
  return (
    <ContentLoader width='100%' height='40' {...props}>
      <circle cx='90%' cy='20' r='16' />
      <rect x='0' y='6' rx='5' ry='5' width='75%' height='10' />
      <rect x='0' y='25' rx='5' ry='5' width='75%' height='10' />
    </ContentLoader>
  )
}
