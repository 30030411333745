import React, { useEffect, useState } from 'react'
import { notify } from '@/_helpers'
import { API, NoContentBox } from '@enkla-gui'

export default function VismaIntegrationCallback() {
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(false)

	useEffect(() => {
		var code = window.location.hash.split('?code=')[1]
		code = code.substring(0, code.indexOf('&'))
		API.post('api/visma/authenticate', {
			code: code
		})
			.then(res => {
				notify.toast('Integration kopplad')
				setLoading(false)
				window.location.hash = '#!/integration/overview'
			})
			.catch(([err, res]) => {
				notify.toast(res, 'error')
				setLoading(false)
				setError(true)
			})
	}, [])

	return (
		<div class='container-absolute-center'>
			{loading && <NoContentBox text='Integrerar...' icon='fa-circle-notch fa-spin' />}
			{error && <NoContentBox text='Något gick fel. Vänligen försök igen.' icon='fa-exclamation-triangle' />}
			{!loading && !error && <NoContentBox text='Integration kopplad! ' icon='fa-thumbs-up' />}
		</div>
	)
}
