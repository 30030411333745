import React from 'react'
import ContentLoader from 'react-content-loader'
//https://skeletonreact.com/
export default function CardLoader(props) {


  return (
    <ContentLoader width="100%"  {...props}>
      <circle cx="25" cy="112" r="15" />
      <rect x="10" y="18" rx="0" ry="0" width="75%" height="9" />
      <rect x="80%" y="15" rx="0" ry="0" width="29%" height="130" />
      <rect x="10" y="41" rx="0" ry="0" width="75%" height="9" />
      <rect x="10" y="65" rx="0" ry="0" width="50%" height="9" />
      <rect x="50" y="98" rx="0" ry="0" width="40%" height="9" />
      <rect x="50" y="115" rx="0" ry="0" width="40%" height="10" />
    </ContentLoader>
  )
}
