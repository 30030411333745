import React, { useState, useCallback } from 'react'
import CollapsableItem from './CollapsableItem'
import { useLocation } from 'react-router-dom'

export default function Collapsable(props) {
  var location = useLocation()
  let [active, setActive] = useState(false)
  if (location.pathname.includes(props.active_key)) active = true
  const msa = useCallback(() => {
    setActive(active)
  }, [active])
  return (
    <li key={props.keyValue} class={`mb-1 sidebar-item sidebar-item-collapsable ${active ? 'active' : ''}`}>
      <button class={`btn btn-toggle align-items-center rounded collapsed ${active ? 'active' : ''}`} onClick={e => setActive(!active)}>
        <i class={`fas fa-fw ${props.icon}`} />
        <span> {props.title}</span>
        {(!props.collapsed || window.innerWidth < 450) && <i class={`fas fa-fw text-right ${active ? 'fa-chevron-down' : 'fa-chevron-left'}`} />}
      </button>
      <div class={`collapse ${active ? 'show' : ''}`}>
        <ul class='btn-toggle-nav list-unstyled fw-normal small'>
          {props.children.map((link, y) => {
            return (
              <CollapsableItem
                {...link}
                badge={
                  props.badges.hasOwnProperty(link.badge?.key) &&
                  props.badges[link.badge.key] !== 0 && (
                    <span class={`badge bg-${link.badge?.color ?? 'light'} sidebar-menu-badge`}>{props.badges[link.badge.key]}</span>
                  )
                }
                keyValue={`${props.keyValue}_${y}`}
                key={`${props.keyValue}_${y}`}
                setActive={msa}
              />
            )
          })}
        </ul>
      </div>
    </li>
  )
}
