import React, { useContext } from 'react'
import Tippy from '@tippyjs/react';
import { PipeySearchContext } from '@providers/PipeyProvider.js';
export default function Explination({ tip }) {

	//hårt beroende mot fastig-gui då ett komponentbibliotek var alldeles för ambitiöst från börjant fq-jj
	const { setSearch } = useContext(PipeySearchContext)
	return (
		<span class='component_Explination' onClick={() => setSearch(tip)}>
			<Tippy content={tip}>
				<i class='far fa-question-circle text-muted'></i>
			</Tippy>
		</span>
	)
}
