
import React, { createContext, useState } from 'react';

export const PipeySearchContext = createContext();

export const PipeyProvider = ({ children }) => {
	const [search, setSearch] = useState("");

	return (
		<PipeySearchContext.Provider value={{ search, setSearch }}>
			{children}
		</PipeySearchContext.Provider>
	);
};
