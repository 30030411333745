import React from 'react'
import { cashmoneyhelper, InfoBoxLoader, Explination } from '@enkla-gui'

export default function InfoBox({
  border,
  subtitle,
  title,
  explination = false,
  link,
  icon = 'fa-info',
  status = 'default',
  transform = false,
  loading = false
}) {
  const _transform = text => {
    if (typeof text !== 'string') return text
    switch (transform) {
      case 'currency':
        return cashmoneyhelper.format(text)
      default:
        return text
    }
  }

  return (
    <div class='component_InfoBox col'>
      <div class='card'>
        <div class={`card-body`}>
          {loading && <InfoBoxLoader />}
          {!loading && (
            <div class='col infobox-wrapper'>
              <div>
                <span class='card-title text-light'>
                  {title} {explination && <Explination tip={explination} />}
                </span>
                <h5 class='card-subtitle text'>{_transform(subtitle)}</h5>
                {link && (
                  <div class='card-link-wrapper'>
                    <a class='card-link' href={link.href}>
                      {link.name} <i class='fas fa-fw fa-arrow-right' />
                    </a>
                  </div>
                )}
              </div>

              <div class='infobox-icon'>
                <div class={`card-display-icon-wrapper ${status}`}>
                  <i class={`fa fa-fw ${icon}`} />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
