import React, { useEffect, useState } from 'react'
import { notify } from '@/_helpers'
import { API, NoContentBox } from '@enkla-gui'

export default function FortnoxIntegrationCallback() {
	const params = new URLSearchParams(window.location.search)
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(false)

	useEffect(() => {
		API.post('api/fortnox/authenticate', {
			state: params.get('state'),
			code: params.get('code')
		})
			.then(res => {
				notify.toast('Integration kopplad')
				setLoading(false)
				window.location.hash = '#!/integration/overview'
			})
			.catch(([err, res]) => {
				notify.toast(res, 'error')
				setLoading(false)
				setError(true)
			})
		params.delete('state')
		params.delete('code')
		window.history.replaceState({}, '', `${window.location.pathname}${params}${window.location.hash}`)
	}, [])

	return (
		<div class='container-absolute-center'>
			{loading && <NoContentBox text='Integrerar...' icon='fa-circle-notch fa-spin' />}
			{error && (
				<div>
					<NoContentBox
						text='Det gick inte att ansluta Fortnox. Vänligen försök igen samt säkerställ att du har en integrationslicens.'
						icon='fa-exclamation-triangle'
					/>
					<a href='https://support.fortnox.se/komigangguide-tjanster/kom-igang-med-partner-appar' target='_blank' rel="noreferrer">
						Klicka här för att läsa mer om Fortnox integrationslicens
					</a>
				</div>
			)}
			{!loading && !error && <NoContentBox text='Integration kopplad! ' icon='fa-thumbs-up' />}
		</div>
	)
}
