import React, { useState } from 'react'
import './styles.scss'

export default function FAQ({ question, children }) {
  const [open, setOpen] = useState(false)

  const toggle = () => {
    setOpen(!open)
  }

  return (
    <div class='component_FAQ'>
      <div class='faq-inner'>
        <div class='header text'>
          <a class='faq-toggle text-light' onClick={() => toggle()}>
            <i class={`fas fa-fw ${!open ? 'fa-plus-square' : 'fa-minus-square icon-toggled'}`} />
            <span>{question}</span>
          </a>
        </div>
        <div hidden={!open} class={`col text-light faq-answer ${open ? 'faq-answer-toggled' : ''}`}>
          {children}
        </div>
      </div>
    </div>
  )
}
