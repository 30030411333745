import React, { useState, useEffect } from 'react'
import { API, InfoBox, ViewHeader } from '@enkla-gui'
import { Line } from 'react-chartjs-2'

export default function Dashboard() {
  let [projected, setProjected] = useState({})
  let [stats, setStats] = useState()
  useEffect(() => {
    API.get(`api/economy/projected`).then(projected => setProjected(projected))
    API.get(`api/economy/stats`).then(stats => setStats(stats))
  }, [])
  return (
    <>
      <ViewHeader icon='fa-chart-line' title='Ekonomi' subtitle='Översikt' />
      <div class='row'>
        <div class='col-6'>
          <div class='row'>
            <InfoBox
              loading={!stats}
              title='Möjlig inkomst'
              icon='fa-dollar-sign'
              transform='currency'
              subtitle={stats?.stat_income_max}
              explination='Möjlig inkomst vid uthyrning av samtliga objekt'
            />
            <InfoBox
              loading={!stats}
              title='Beräknad inkomst'
              icon='fa-dollar-sign'
              transform='currency'
              subtitle={stats?.stat_income_current}
              explination='Beräknad inkomst per månad baserad på alla uthyrningar'
            />
          </div>
          <div class='row'>
            <InfoBox
              loading={!stats}
              title='R12 inkomst'
              icon='fa-dollar-sign'
              transform='currency'
              subtitle={stats?.stat_income_r12}
              explination='R12 Inkomst baserad på alla uthyrningar, kommande och upphörande.'
            />
            <InfoBox
              loading={!stats}
              title='Totala uthyrningar'
              icon='fa-key'
              subtitle={stats?.stat_leases_total}
              explination='Beräknad inkomst per månad baserad på alla uthyrningar'
            />
          </div>
        </div>
        <div class='row'>
          <pre>{JSON.stringify(stats, null, 2)}</pre>
        </div>
      </div>
    </>
  )
}
