import React from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { icons } from '@config'
import { ViewHeader, Card, Submit, Input, Select, useGet, useSubmit } from '@enkla-gui'

export default function Edit() {
  const { id } = useParams()
  const history = useHistory()
  const { data: tenant } = useGet({ endpoint: `api/tenant/${id}` })
  var { register, post, getValues, error, loading, handleSubmit } = useSubmit({
    endpoint: 'api/tenant',
    form: tenant,
    method: 'patch',
    success: () => {
      history.push(`/tenant/${id}`)
    }
  })

  const submit = handleSubmit(data => {
    data.organisation = data.organisation === 'true'
    post(data)
  })

  return (
    <>
      <ViewHeader
        loading={!tenant}
        title='Hyresgäst'
        icon={icons.tenant}
        subtitle='Redigera'
        return={{ link: `#!/tenant/${id}`, title: getValues('name') }}
        buttons={[{ icon: 'fa-times', title: 'Avbryt', href: `#!/tenant/${id}`, position: 'right' }]}
      />
      <Card
        loading={!tenant}
        buttons={{
          footer: [<Submit onSubmit={submit} text='Uppdatera' sending={loading} />]
        }}>
        <input type='hidden' {...register('tenant_id')} value={id} />
        <div class='row mb-3'>
          <Select
            controls={{ register }}
            name='organisation'
            label='Kundtyp'
            error={error}
            values={[
              { key: 'Privatkund', value: false },
              { key: 'Företagskund', value: true }
            ]}
          />
        </div>
        <div class='row mb-3'>
          <Input controls={{ register }} name='name' options={{ required: true }} label='Namn' error={error} />
          <Input controls={{ register }} name='identifier' options={{ required: true }} label='Identifikation' error={error} />
        </div>
        <div class='row mb-3'>
          <Input controls={{ register }} name='phone' options={{ required: false }} label='Telefonnummer' error={error} />
          <Input controls={{ register }} name='email' options={{ required: false }} label='E-post' error={error} />
        </div>
        <div class='row'>
          <Input
            controls={{ register }}
            name='billing_email'
            options={{ required: false }}
            label='E-post (Fakturering)'
            placeholder='Kontakt-e-post används för fakturor om inget annat anges'
            error={error}
          />
        </div>
      </Card>
    </>
  )
}
