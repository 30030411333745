import React from 'react'
import { date } from '@/_helpers'
import { BeefyButton } from '@enkla-gui'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

export default function EntityLeaseStatus(props) {
  const history = useHistory()
  const displayCreateButton = () => {
    if (props?.entity?.leases?.filter(a => !a.stop).length > 0) return
    return (
      <BeefyButton
        _class='mb-1'
        _default={{
          text: 'Skapa Uthyrning',
          icon: 'fas fa-home'
        }}
        hover={{
          text: 'Hyr ut',
          icon: 'fas fa-key'
        }}
        type='info'
        callback={() => history.push(`/lease/create/entity/${props.entity.id}`)}
      />
    )
  }
  return (
    <div class='component_EntityLeaseStatus mb-15'>
      <div class='row'>
        <div class='col'>
          {props?.entity?.leases?.map(lease => {
            switch (lease.state.status) {
              case 'active':
                return (
                  <BeefyButton
                    _class='mb-1'
                    _default={{
                      text: `${props.entity.type.name}en har en aktiv uthyrning`,
                      icon: lease.state.icon
                    }}
                    hover={{
                      text: 'Visa uthyrning',
                      icon: 'far fa-arrow-alt-circle-right'
                    }}
                    type={lease.state.color}
                    callback={() => history.push(`/lease/${lease.id}`)}
                  />
                )

              case 'inactive':
                return (
                  <BeefyButton
                    _class='mb-1'
                    _default={{
                      text: `${props.entity.type.name}en har en avslutad uthyrning som kommer att arkiveras ${moment
                        .unix(lease.stop)
                        .add(30, 'days')
                        .format('YYYY-MM-DD')}`,
                      icon: lease.state.icon
                    }}
                    hover={{
                      text: 'Visa uthyrning',
                      icon: 'fas fa-arrow-alt-circle-right'
                    }}
                    type={lease.state.color}
                    callback={() => history.push(`/lease/${lease.id}`)}
                  />
                )

              case 'ending':
                return (
                  <BeefyButton
                    _class='mb-1'
                    _default={{
                      text: `${props.entity.type.name}en har en upphörande uthyrning (${date.short(lease.stop)})`,
                      icon: lease.state.icon
                    }}
                    hover={{
                      text: 'Visa uthyrning',
                      icon: 'far fa-arrow-alt-circle-right'
                    }}
                    type={lease.state.color}
                    callback={() => history.push(`/lease/${lease.id}`)}
                  />
                )

              case 'planned':
                return (
                  <BeefyButton
                    _class='mb-1'
                    _default={{
                      text: `${props.entity.type.name}en har en kommande uthyrning (${date.short(lease.start)}${
                        lease.stop ? ' - ' + date.short(lease.stop) : ''
                      })`,
                      icon: lease.state.icon
                    }}
                    hover={{
                      text: 'Visa uthyrning',
                      icon: 'fas fa-arrow-alt-circle-right'
                    }}
                    type={lease.state.color}
                    callback={() => history.push(`/lease/${lease.id}`)}
                  />
                )
              default:
                return ''
            }
          })}
          {displayCreateButton()}
        </div>
      </div>
    </div>
  )
}
