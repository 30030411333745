import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { notify } from '@/_helpers'
import { API, ViewHeader, Card, Submit, Input, Select, TextArea } from '@enkla-gui'

export default function CreateTicket() {
  const { register, handleSubmit, setValue } = useForm()
  const [error, setError] = useState(null)
  const [entities, setEntities] = useState(false)
  const [tenants, setTenants] = useState(false)
  const [sending, setSending] = useState(false)
  const { id, type } = useParams()
  const history = useHistory()

  useEffect(() => {
    switch (type) {
      case 'tenant':
        API.get(`api/tenant/${id}`).then(tenant => {
          var entities = tenant.leases.map(lease => lease.entity)
          setEntities(entities)
          setValue('entity_id', entities[0].id)
          setValue('tenant_id', tenant.id)
          setTenants([tenant])
        })
        break
      case 'entity':
        API.get(`api/entity/${id}`).then(entity => {
          var tenants = []
          entity.leases.map(lease => tenants.push(...lease.tenants))
          setTenants(tenants)
          setEntities([entity])
          tenants.length && setValue('tenant_id', tenants[0].id)
          setValue('entity_id', entity.id)
        })
        break
      default:
        break
    }
  }, [id])

  const onSubmit = data => {
    setSending(true)
    API.post('api/ticket', data)
      .then(res => {
        notify.toast('Ärendet har skapats')
        history.push(`/ticket/${res.ticket.id}`)
        setSending(false)
      })
      .catch(([err, res]) => {
        setSending(false)
        setError({
          message: res.message,
          data: res.data
        })
      })
  }

  return (
    <div class='row'>
      <ViewHeader loading={!tenants || !entities} title='Ärende' subtitle='Skapa' icon='fa-envelope-open-text' />
      <Card
        loading={!tenants || !entities}
        buttons={{
          footer: [<Submit onSubmit={handleSubmit(onSubmit)} text='Skapa' sending={sending} />]
        }}>
        <input type='hidden' {...register('entity_id')} value={id} />
        <div class='mb-3'>
          <Input controls={{ register }} name='subject' options={{ required: true }} label='Ämne' error={error} />
        </div>
        <div class='row mb-3'>
          <div class='col'>
            <Select
              controls={{ register }}
              name='type'
              options={{ required: true }}
              label='Ärendestyp'
              error={error}
              values={[
                { key: 'Internt', value: 'INTERNAL' },
                { key: 'Felanmälan', value: 'REPORT' },
                { key: 'Fråga', value: 'QUESTION' }
              ]}
            />
          </div>
        </div>
        <div class='row'>
          {tenants && tenants.length !== 0 && (
            <div class='col'>
              <Select
                clearable={type !== 'tenant'}
                disabled={type === 'tenant'}
                controls={{ register, setValue }}
                name='tenant_id'
                options={{ required: false }}
                label='Hyresgäst'
                error={error}
                values={tenants.map(tenant => ({ key: tenant.name, value: tenant.id }))}
              />
            </div>
          )}
          {entities && (
            <div class='col'>
              <Select
                disabled={type === 'entity'}
                controls={{ register }}
                name='entity_id'
                options={{ required: false }}
                label='Objekt'
                error={error}
                values={entities.map(entity => ({ key: entity.name, value: entity.id }))}
              />
            </div>
          )}
        </div>
        <TextArea controls={{ register }} name='description' options={{ required: true }} label='Beskrivning' error={error} />
      </Card>
    </div>
  )
}
