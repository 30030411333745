import React from 'react'

export default function NoContentBox(props) {
  return (
    <div class='component_NoContentBox'>
      <div class='ncb-inner'>
        <i class={'fas fa-fw ' + props.icon}></i>
        <span>{props.text}</span>
      </div>
    </div>
  )
}
