import React from 'react'
import { Controller } from 'react-hook-form'
import { date, Explination } from '@enkla-gui'
import DatePicker from 'react-datepicker'

import sv from 'date-fns/locale/sv'

export default function Date({ controls, today = false, name, placeholder, label, tip = false, error = false, cols = 'col' }) {
  var { control } = controls
  var message
  const hasError = () => {
    var data = error.data
    if (data === undefined) return false
    name.split('.').forEach(value => {
      if (value in data) {
        data = data[value]
      } else {
        data = []
      }
    })
    if (data.length === 0) return false
    message = Array.isArray(data) ? data[0] : data
    return true
  }
  return (
    <div class={`component_Date ${cols}`}>
      <div class='form-group'>
        <label>
          {label} {tip && <Explination tip={tip} />}
        </label>
        <Controller
          control={control}
          name={name}
          render={({ field }) => (
            <DatePicker
              className='form-control'
              locale={sv}
              dateFormat='yyyy-MM-dd'
              placeholderText={placeholder}
              onChange={_date => field.onChange(_date === null ? (today ? date.today() : null) : date.fixTimezone(_date) / 1000)}
              selected={field.value * 1000}
            />
          )}
        />
        {error && hasError() && (
          <div class='form-inline-error'>
            <i class='fa fa-fw fa-exclamation-triangle' /> {message}
          </div>
        )}
      </div>
    </div>
  )
}
